import React, { useRef, useState, useEffect } from "react"


const index = (id_jadwal, id_jadwal_asesi) => {
  const userData = JSON.parse(localStorage.getItem("authUser"))
  // Membuat elemen video untuk menampilkan feed dari kamera
  const videoElement = document.createElement("video");
  document.body.appendChild(videoElement);

  // Membuat elemen canvas untuk menampilkan gambar yang akan diambil
  const canvasElement = document.createElement("canvas");
  canvasElement.style.display = "none"; // Sembunyikan elemen canvas
  document.body.appendChild(canvasElement);

  // Mengakses kamera dan menampilkan feed kamera di elemen video
  navigator.mediaDevices
    .getUserMedia({ video: true })
    .then((stream) => {
      videoElement.srcObject = stream;
      videoElement.play();

      const proctoring = localStorage.getItem("proctoring_status")

      if (proctoring === "on") {
        setTimeout(() => {
          canvasElement.width = videoElement.videoWidth;
          canvasElement.height = videoElement.videoHeight;
          const context = canvasElement.getContext("2d");
          context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

          // Mengambil gambar sebagai objek Blob
          canvasElement.toBlob((blob) => {
            // Cetak objek Blob ke konsol
            console.log("Objek Blob Gambar:", blob);
            var myHeaders = new Headers()
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("access_token_2")
            )
            var formdata = new FormData()
            formdata.append("id_jadwal", id_jadwal)
            formdata.append("nip", userData.nip)
            formdata.append("nama", userData.nama_peserta)
            formdata.append("id_jadwal_asesi", id_jadwal_asesi)
            formdata.append("image", blob)

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow",
            }

            fetch(process.env.REACT_APP_BACKEND_URL_VERSION + "capture", requestOptions)
              .then(response => response.text())
              .then(result => {
                const Response = JSON.parse(result)
                if (Response.meta.code == 200) {
                  return console.log("berhasil menyimpan foto")
                }
              })
              .catch(error => {
                console.log("error", error)
              })
          }, "image/jpeg");
          videoElement.style.display = "none";
        }, 2000); // Tunggu 2 detik sebelum mengambil gambar (sesuaikan dengan kebutuhan

      }
      // Mengambil gambar setelah feed kamera tersedia
      setTimeout(() => {
        canvasElement.width = videoElement.videoWidth;
        canvasElement.height = videoElement.videoHeight;
        const context = canvasElement.getContext("2d");
        context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

        // Mengambil gambar sebagai objek Blob
        canvasElement.toBlob((blob) => {
          // Cetak objek Blob ke konsol
          console.log("Objek Blob Gambar:", blob);
          var myHeaders = new Headers()
          myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("access_token_2")
          )
          var formdata = new FormData()
          formdata.append("id_jadwal", id_jadwal)
          formdata.append("nip", userData.nip)
          formdata.append("nama", userData.nama_peserta)
          formdata.append("id_jadwal_asesi", id_jadwal_asesi)
          formdata.append("image", blob)

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
          }

          fetch(process.env.REACT_APP_BACKEND_URL_VERSION + "capture", requestOptions)
            .then(response => response.text())
            .then(result => {
              const Response = JSON.parse(result)
              if (Response.meta.code == 200) {
                return console.log("berhasil menyimpan foto")
              }
            })
            .catch(error => {
              console.log("error", error)
            })
        }, "image/jpeg");
        videoElement.style.display = "none";
      }, 2000); // Tunggu 2 detik sebelum mengambil gambar (sesuaikan dengan kebutuhan

    })
    .catch((error) => {
      console.error("Gagal mengakses kamera:", error);
    });

}

export default index
