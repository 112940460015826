import React, { useEffect, useMemo, useState } from "react"
import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Table,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    CardHeader,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"
import { select } from "redux-saga/effects"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import email from "../../../../src/assets/images/sosial_media/email.svg"
import memo from "../../../../src/assets/images/sosial_media/memo.png"
import whatsapp from "../../../../src/assets/images/sosial_media/Whatsapp.svg"
import instagram from "../../../../src/assets/images/sosial_media/Instagram.png"
import facebook from "../../../../src/assets/images/sosial_media/Facebook.svg"
import twitter from "../../../../src/assets/images/sosial_media/Twitter.svg"
import kalender from "../../../../src/assets/images/sosial_media/kalender.svg"
import other from "../../../../src/assets/images/sosial_media/other.svg"
import classnames from "classnames"



const JawabanMatriksUTT = (props) => {
    const data = props.jawaban
    const status = props.status
    const soal = props.soal
    const jenis_soal = soal.jenis_soal
    const studikasus = props.case
    const listSosmed = props.listSosmed
    const skenario = props.skenario
    const [sosmed, setSosmed] = useState()
    const [margePaketSosmed, setMargePaketSosmed] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    console.log(data);

    //menggabungkan sosmed yang sama
    useEffect(() => {
        if (listSosmed && listSosmed.length > 0) {
            const margePaketSosmed = [
                ...new Set(listSosmed.map(item => item.sosial_media)),
            ]

            console.log(margePaketSosmed)
            setMargePaketSosmed(margePaketSosmed)
        }
    }, [listSosmed])

    useEffect(() => {
        if (margePaketSosmed && margePaketSosmed.length > 0) {
            const filteredData = margePaketSosmed.map(sosial_media => {
                return listSosmed.find(item => item.sosial_media === sosial_media)
            })

            setFilteredData(filteredData)
        }
    }, [margePaketSosmed])


    const [openAccordions, setOpenAccordions] = useState([])

    const toggleAccordion = index => {
        const updatedOpenAccordions = [...openAccordions]
        updatedOpenAccordions[index] = !updatedOpenAccordions[index]
        setOpenAccordions(updatedOpenAccordions)
    }

    useEffect(() => {
        if (filteredData && filteredData.length > 0) {
            const sosmedView = filteredData[0].sosial_media
            setSosmed(sosmedView)
        }
    }, [filteredData])

    const checkJawabanMatrix = (jawaban_utt, item, index) => {
        const skala = JSON.parse(item.value);
        const jawaban = jawaban_utt.find(
            itemJawaban => itemJawaban.ju_id_msoal_jawaban === item.id_soal_jawaban && itemJawaban.ju_value === skala[index]
        )
        return jawaban ? true : false;
    }
    return (
        <React.Fragment>
            {soal.jenis_soal && jenis_soal == "studi kasus" && studikasus && (
                <Card>
                    <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                        {studikasus.case_name}
                    </CardHeader>
                    <CardBody>
                        <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                                __html: studikasus.case,
                            }}
                        ></div>
                    </CardBody>
                </Card>
            )}
            {soal.jenis_soal && jenis_soal == "skenario" && skenario && (
                <Card>
                    <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                        {skenario.judul_skenario}
                    </CardHeader>
                    <CardBody>
                        <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                                __html: skenario.isi_skenario,
                            }}
                        ></div>
                    </CardBody>
                </Card>
            )}
            {soal.jenis_soal && jenis_soal == "inbasket" && listSosmed && (
                <Row>
                    <Col xl={2}>
                        <Card className="bg-transparent border border-primary shadow shadow-lg">
                            <CardBody className="text-center ms-3">
                                {filteredData && filteredData.length > 0 && filteredData.map((item, index) => (
                                    <a
                                        key={index}
                                        className="btn btn-transparant  me-3"
                                        role="button"
                                        onClick={() => setSosmed(item.sosial_media)}
                                    >
                                        <img
                                            className="email"
                                            src={
                                                item.sosial_media === "wa"
                                                    ? whatsapp
                                                    : item.sosial_media === "Memo"
                                                        ? memo
                                                        : item.sosial_media === "Twitter"
                                                            ? twitter
                                                            : item.sosial_media === "Email"
                                                                ? email
                                                                : item.sosial_media === "Facebook"
                                                                    ? facebook
                                                                    : item.sosial_media === "Instagram"
                                                                        ? instagram
                                                                        : item.sosial_media === "Kalender"
                                                                            ? kalender
                                                                            : item.sosial_media === 'Lainnya'
                                                                                ? other
                                                                                : ''
                                            }
                                            alt={item.sosial_media}
                                        />
                                    </a>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={10}>
                        <Card className="bg-transparent border border-primary shadow shadow-lg">
                            <CardBody>
                                <label className="fs-5 fw-semibold">
                                    Sosial Media : {sosmed}
                                </label>
                                {listSosmed && listSosmed.length > 0 && listSosmed.map((item, index) => (
                                    <div key={index}>
                                        {item.sosial_media === sosmed && (
                                            <>
                                                <div className="accordion mt-3" id="accordion">
                                                    <div className="accordion-item" key={index}>
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button
                                                                className={classnames(
                                                                    "accordion-button",
                                                                    "fw-medium",
                                                                    { collapsed: !openAccordions[index] }
                                                                )}
                                                                type="button"
                                                                onClick={() => toggleAccordion(index)}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                {item.judul}
                                                            </button>
                                                        </h2>

                                                        <Collapse
                                                            isOpen={openAccordions[index]}
                                                            className="accordion-collapse"
                                                        >
                                                            <div className="accordion-body">
                                                                <CardBody className="bg-light rounded rounded-3">
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item.isi,
                                                                        }}
                                                                    />
                                                                </CardBody>
                                                            </div>
                                                        </Collapse>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
            {data && data.map((item, index) => (
                <React.Fragment key={index}>
                    <Card className="shadow shadow-lg">
                        <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                            Soal
                        </CardHeader>
                        <CardBody>
                            <div
                                className="mt-2"
                                dangerouslySetInnerHTML={{
                                    __html: item?.soal?.soal,
                                }}
                            ></div>
                        </CardBody>
                        <CardBody>
                            <table className="table table-striped">
                                <thead>
                                    <tr className="text-center fw-bold">
                                        <th></th>
                                        {item.soal && JSON.parse(item.soal.skala).map((item2, index2) => (
                                            <th key={index2}>
                                                {" "}
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: item2,
                                                    }}
                                                ></div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.jawaban.map((item3, innerIndex) => (
                                        <tr className="text-center fw-bold" key={innerIndex}>
                                            <td>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: item3.pilihan,
                                                    }}
                                                ></div>
                                            </td>
                                            {item.soal && JSON.parse(item.soal.skala).map((item4, index4) => (
                                                <td key={index4}>
                                                    {status !== 'selesai' ? (
                                                        <input
                                                            type="radio"
                                                            className="form-check-input border border-2 border-primary"
                                                            // name={`pilihan_${outerIndex}${innerIndex}`}
                                                            checked={checkJawabanMatrix(item.jawaban_utt, item3, index4)}

                                                        // ked={
                                                        //     item.jawaban_utt.some(jawabanUtt =>
                                                        //         jawabanUtt.ju_id_msoal_jawaban === item3.id_soal_jawaban &&
                                                        //         JSON.parse(item3.value)[index4] === jawabanUtt.value
                                                        //     )
                                                        // }
                                                        />
                                                    ) : (
                                                        <input
                                                            type="radio"
                                                            className="form-check-input border border-2 border-primary"
                                                            // name={`pilihan_${outerIndex}${innerIndex}`}
                                                            checked={
                                                                checkJawabanMatrix(item.jawaban_utt, item3, index4)
                                                            }
                                                        />
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </CardBody>
                    </Card>
                </React.Fragment>
            ))}
        </React.Fragment>
    )
}

export default JawabanMatriksUTT