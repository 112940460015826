import React from "react"
import { Navigate } from "react-router-dom"

// Simulasi
import Simulasi from "../pages/Simulasi/all-simulasi"

import ListSimulasi from "../pages/DetailSimulasi/DetailSimulasi"

// Jadwal
import Jadwal from "../pages/Jadwal/all-jadwal"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//monitoring


//route peserta
//jadwal
import JadwalPesrta from "../pages/PagePeserta/list-jadwal/all-jadwal"
//profile
import Profile from "../pages/PagePeserta/profile/profile"
//list soal
import ListSoal from "../pages/PagePeserta/list-soal/ListSoal"

import ListSoalPreview from "pages/Preview/ListSoalPreview"

//monitoring
import MonitoringCI from "../pages/Monitoring/monitoring-ci/MonitoringCI"
import MonitoringUTT from "pages/Monitoring/monitoring-utt/MonitoringUTT"
//soal
import SoalCA from "../pages/PagePeserta/Soal/ca/soal-ca"
import SoalWawancara from "../pages/PagePeserta/Soal/wawancara/soal-wawancara"
import SoalCI from "../pages/PagePeserta/Soal/ci/soal-ci"
import SoalFGD from "../pages/PagePeserta/Soal/grupdiskusi/soal-fgd"
import SoalPresentsi from "../pages/PagePeserta/Soal/presentasi/soal-presentasi"
import SoalMengurutkan from "../pages/PagePeserta/Soal/mengurutkan/soal-mengurutkan"
import SoalInbasket from "../pages/PagePeserta/Soal/inbasket/soal-inbasket"
import UploadFile from "../pages/PagePeserta/Soal/uploadfile/UploadFile"

import SoalCAPreview from "../pages/Preview/Soal/ca/soal-ca"
import SoalWawancaraPreview from "../pages/Preview/Soal/wawancara/soal-wawancara"
import SoalCIPreview from "../pages/Preview/Soal/ci/soal-ci"
import SoalFGDPreview from "../pages/Preview/Soal/grupdiskusi/soal-fgd"
import SoalPresentsiPreview from "../pages/Preview/Soal/presentasi/soal-presentasi"
import SoalMengurutkanPreview from "../pages/Preview/Soal/mengurutkan/soal-mengurutkan"
import SoalInbasketPreview from "../pages/Preview/Soal/inbasket/soal-inbasket"
import UploadFilePreview from "../pages/Preview/Soal/uploadfile/UploadFile"


//auth
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//  // Inner Authentication

import Pages404 from "components/Utility/Pages404"
//jumper login
import JumperLogin from 'components/JumperLogin/index'
import Jumper from "pages/Authentication/Jumper"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },


  //File Manager
  { path: "/simulasi/:id", component: <Simulasi /> },

  //listsimulasi
  { path: "/simulasi/list/:id", component: <ListSimulasi /> },

  //jadwal

  { path: "/jadwal", component: <Jadwal /> },


  //profile
  // { path: "/Profile", component: <Profile /> },

  //list sola
  { path: "/ListJadwal", component: <JadwalPesrta /> },

  //Monitoring
  { path: "/Monitoring/:idJadwal/:idPeserta/:idSimulasi/:simulasi", component: <MonitoringCI /> },
  { path: "/MonitoringUTT/:idJadwal/:idPeserta/:idSimulasi/:simulasi", component: <MonitoringUTT /> },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  { path: "/ListSoalPreview/:id", component: <ListSoalPreview /> },

  { path: "/preview/SoalCA/:id/:id2", component: <SoalCAPreview /> },
  { path: "/preview/SoalWawancara/:id/:id2", component: <SoalWawancaraPreview /> },
  { path: "/preview/SoalCI/:id/:id2", component: <SoalCIPreview /> },
  { path: "/preview/SoalFGD/:id/:id2", component: <SoalFGDPreview /> },
  { path: "/preview/SoalPresentasi/:id/:id2", component: <SoalPresentsiPreview /> },
  { path: "/preview/SoalMengurutkan/:id/:id2", component: <SoalMengurutkanPreview /> },
  { path: "/preview/SoalInbasket/:id/:id2", component: <SoalInbasketPreview /> },
  { path: "/preview/UploadFile/:idSimulasi/:idSimulasi2", component: <UploadFilePreview /> },
  { path: "/preview/ListSoal/:id", component: <ListSoalPreview /> },
]
const authPesertaRoutes = [
  { path: "/Profile", component: <Profile /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/Profile" />,
  },
  //soal
  { path: "/SoalCA/:id/:id2", component: <SoalCA /> },
  { path: "/SoalWawancara/:id/:id2", component: <SoalWawancara /> },
  { path: "/SoalCI/:id/:id2", component: <SoalCI /> },
  { path: "/SoalFGD/:id/:id2", component: <SoalFGD /> },
  { path: "/SoalPresentasi/:id/:id2", component: <SoalPresentsi /> },
  { path: "/SoalMengurutkan/:id/:id2", component: <SoalMengurutkan /> },
  { path: "/SoalInbasket/:id/:id2", component: <SoalInbasket /> },
  { path: "/UploadFile/:idSimulasi/:idSimulasi2", component: <UploadFile /> },
  { path: "/ListSoal/:id", component: <ListSoal /> },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/catac/:token/:asesi/:simulasi/:tipe/:proctoring", component: <JumperLogin /> },
  { path: "/jumper/:nip/:password/:idTemplate", component: <Jumper /> },
  { path: "/ListSoalPreview/:id", component: <ListSoalPreview /> },

  { path: "/preview/SoalCA/:id/:id2", component: <SoalCAPreview /> },
  { path: "/preview/SoalWawancara/:id/:id2", component: <SoalWawancaraPreview /> },
  { path: "/preview/SoalCI/:id/:id2", component: <SoalCIPreview /> },
  { path: "/preview/SoalFGD/:id/:id2", component: <SoalFGDPreview /> },
  { path: "/preview/SoalPresentasi/:id/:id2", component: <SoalPresentsiPreview /> },
  { path: "/preview/SoalMengurutkan/:id/:id2", component: <SoalMengurutkanPreview /> },
  { path: "/preview/SoalInbasket/:id/:id2", component: <SoalInbasketPreview /> },
  { path: "/preview/UploadFile/:idSimulasi/:idSimulasi2", component: <UploadFilePreview /> },
  { path: "/preview/ListSoal/:id", component: <ListSoalPreview /> },
]

export { authProtectedRoutes, publicRoutes, authPesertaRoutes, Pages404 };

