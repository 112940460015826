import React from 'react';
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import { MD5 } from 'crypto-js';


const Presentation = (props) => {

    const urlCallback = process.env.REACT_APP_BACKEND_URL + "/CallBack/" + props.id_peserta + "/" + props.id_simulasi + "/" + props.id_jadwal;
    const nameFile = "Presentasi_" + props.id_peserta + "_" + props.id_simulasi + "_" + props.id_jadwal + ".pptx"
    const onDocumentReady = () => {
        console.log("Document is loaded");
    };

    const generateRandomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const randomString = generateRandomString(10); // Generate a random string of length 10

    const onRequestSave = () => {
        // const editor = document.getElementById("docxEditor");
        // editor.api.reloadEditor();
    };


    return (
        <DocumentEditor
            id="docxEditor"
            documentServerUrl="https://onlyoffice.alfahuma.biz.id"
            config={{
                document: {
                    fileType: "pptx",
                    key: String(randomString),
                    title: nameFile,
                    url: process.env.REACT_APP_BACKEND_URL + props.dir,
                },
                documentType: "slide",
                editorConfig: {
                    callbackUrl: urlCallback,
                    mode: props.mode,
                },
            }}
            events={{
                onDocumentReady: onDocumentReady,
                onRequestSave: onRequestSave,
            }}
        />
    );
};

export default Presentation;
