import React, { useEffect, useMemo, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import DetailAlattest from "pages/DetailSimulasi/DetailAlattest";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { io } from "socket.io-client";
import Swal from "sweetalert2";

const ListPotensi = (props) => {
  const data = props.dataSimulasi;
  const idJadwal = props.idJadwal;
  const user = JSON.parse(localStorage.getItem('authUser'));

  // const socket = io.connect(process.env.REACT_APP_BACKEND_SOCKET_URL, {
  //   transports: ["websocket"],
  // path: '/socket-ac/socket.io',
  //   extraHeaders: {
  //     "Access-Control-Allow-Origin": process.env.REACT_APP_URL
  //   }
  // });

  // const updateStatusStop = async (id_simulasi) => {
  //   const confirmed = await Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'Do you want to stop the simulation?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, stop it!'
  //   });

  //   if (confirmed.isConfirmed) {
  //     const message = id_simulasi;
  //     const status = false;
  //     socket.emit("send_broadcast", { message, status });
  //   }
  // };

  // const updateStatusStart = async (id_simulasi) => {
  //   const confirmed = await Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'Do you want to start the simulation?',
  //     icon: 'info',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, start it!'
  //   });

  //   if (confirmed.isConfirmed) {
  //     const message = id_simulasi;
  //     const status = true;
  //     socket.emit("send_broadcast", { message, status });
  //   }
  // };

  return (
    <React.Fragment>
      <div>
        <Breadcrumbs title="Dashboard" {...(data.length > 0 && data[0].kompetensi.length > 0 ? { breadcrumbItem: 'Potensi' } : { breadcrumbItem: 'Tidak ada data Potensi' })} />
        {data.length > 0 && data[0].potensi.length > 0 && (
          data[0].potensi.map(item => (
            <Card key={item.id_mjadwal_subtest}>
              <CardBody>
                <CardTitle className="bg-primary p-3 rounded text-white">
                  <Row>
                    {/* <Col lg={4}>
                      {item.mjs_tanggal_pelaksanaan_mulai} {item.mjs_jam_pelaksanaan_mulai} - {item.mjs_tanggal_pelaksanaan_selesai} {item.mjs_jam_pelaksanaan_selesai}
                    </Col> */}
                    <Col className="text-center" lg={12}>
                      {item.mja_alattest} - {item.mjst_subtest}
                    </Col>
                    {/* {user.role_id !== 20 && (
                      <Col className="text-end" lg={4}>
                        <Button className="btn btn-danger mx-1" onClick={() => updateStatusStop(item.mjs_id_simulasi)}>
                          <span className="mdi mdi-timer-stop-outline"></span> Stop
                        </Button>
                        <Button className="btn btn-primary mx-1" onClick={() => updateStatusStart(item.mjs_id_simulasi)}>
                          <span className="mdi mdi-timer-play-outline"></span> Start
                        </Button>
                      </Col>
                    )} */}
                  </Row>
                </CardTitle>
                <Row>
                  <Col>
                    <DetailAlattest data={item.mjst_id_subtest} idJadwal={idJadwal} subtest={item.mjst_id_subtest} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))
        )}
      </div>
      <br></br>
      <div>

        <Breadcrumbs title="Dashboard" {...(data.length > 0 && data[0].kompetensi.length > 0 ? { breadcrumbItem: 'Kompetensi' } : { breadcrumbItem: 'Tidak ada data Kompetensi' })} />
        {data.length > 0 && data[0].kompetensi.length > 0 && (
          data[0].kompetensi.map(item => (
            <Card key={item.id_mjadwal_subtest}>
              <CardBody>
                <CardTitle className="bg-primary p-3 rounded text-white">
                  <Row>
                    {/* <Col lg={4}>
                      {item.mjs_tanggal_pelaksanaan_mulai} {item.mjs_jam_pelaksanaan_mulai} - {item.mjs_tanggal_pelaksanaan_selesai} {item.mjs_jam_pelaksanaan_selesai}
                    </Col> */}
                    <Col className="text-center" lg={12}>
                      {item.mja_alattest} - {item.mjst_subtest}
                    </Col>
                    {/* {user.role_id !== 20 && (
                      <Col className="text-end" lg={4}>
                        <Button className="btn btn-danger mx-1" onClick={() => updateStatusStop(item.mjs_id_simulasi)}>
                          <span className="mdi mdi-timer-stop-outline"></span> Stop
                        </Button>
                        <Button className="btn btn-primary mx-1" onClick={() => updateStatusStart(item.mjs_id_simulasi)}>
                          <span className="mdi mdi-timer-play-outline"></span> Start
                        </Button>
                      </Col>
                    )} */}
                  </Row>
                </CardTitle>
                <Row>
                  <Col>
                    <DetailAlattest data={item.mjst_id_subtest} idJadwal={idJadwal} simulasi={item.mjst_subtest} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))
        )}
      </div>
    </React.Fragment>
  );
};

export default ListPotensi;
