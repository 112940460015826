import React, { useEffect, useMemo, useState } from "react"
import { db } from "components/Firebase/Firebase"
import {
    collection,
    query,
    where,
    getDocs,
    updateDoc,
    doc,
    addDoc,
} from "firebase/firestore"
import { Link, useNavigate, useParams } from "react-router-dom"
import { io } from "socket.io-client";
import Swal from "sweetalert2"

const StatusPeserta = props => {
    // const [dataFirebase, setDataFirebase] = useState([]);
    const [statusTimer, setStatusTimer] = useState(null);

    useEffect(() => {
        var myHeaders = new Headers()
        myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("access_token_2")
        )

        var requestOptionsGet = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        }
        const baseURLGet =
            process.env.REACT_APP_BACKEND_URL_VERSION +
            `jawaban/status-timer-utt?ja_id_peserta=` +
            props.id_peserta +
            `&ssst_id_sub_test=` +
            props.id_simulasi +
            `&id_mjadwal=` +
            props.id_jadwal;
        fetch(baseURLGet, requestOptionsGet)
            .then(response => response.json())
            .then(result => {
                if (result.meta.code == 200) {
                    setStatusTimer(result.data.timerStatus.status)
                }
            })
            .catch(error => {
                console.log("error", error)
            })
    }, [])

    return (
        <div>
            {
                statusTimer == true ? (
                    <Link className="btn btn-sm btn-soft-success">
                        <span className=" mdi mdi-timer"></span>
                    </Link>
                ) : statusTimer == false ? (
                    <Link className="btn btn-sm btn-soft-danger">
                        <span className=" mdi mdi-timer"></span>
                    </Link>
                ) : null
            }
        </div>
    )
}
export default StatusPeserta
