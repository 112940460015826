export default class Base64UploadAdapter {
    constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then(file => {
                if (!file) {
                    throw new Error('No file provided.');
                }
                if (file.size > 300 * 1024) {
                    throw new Error('File size exceeds the limit of 300KB.');
                }
                return this.convertFileToBase64(file);
            })
            .then(base64 => ({ default: base64 }));
    }

    // Converts a File object to base64.
    convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                console.log('Base64 string:', base64); // Log the base64 string
                resolve(base64);
            };
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }
}
