import React from "react"

import { Row, Col, Card, CardBody, CardHeader, Table } from "reactstrap"
import { Link } from "react-router-dom"

const DetailPeserta = (props) => {
  const data = props.data
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <CardBody>
          <CardHeader className="bg-primary p-3 rounded">
            <h5 className="text-white">Informasi Peserta</h5>
          </CardHeader>
          <div className="table-responsive">
            {data && (
              <Table className="table mb-0">
                <tbody>
                  <tr>
                    <th>NIP</th>
                    <th>:</th>
                    <td>{data.nip}</td>
                  </tr>
                  <tr>
                    <th>Nama</th>
                    <th>:</th>
                    <td>{data.nama_peserta}</td>
                  </tr>
                  <tr>
                    <th>Instansi</th>
                    <th>:</th>
                    <td>{data.instansi?.nama_instansi}</td>
                  </tr>
                  <tr>
                    <th>Jabatan</th>
                    <th>:</th>
                    <td>{data.jabatan}</td>
                  </tr>
                  <tr>
                    <th>Pemdidikan</th>
                    <th>:</th>
                    <td>{data.pendidikan}</td>
                  </tr>
                  <tr>
                    <th>Umur</th>
                    <th>:</th>
                    <td>{data.umur}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <th>:</th>
                    <td>{data.status}</td>
                  </tr>
                  <tr>
                    <th>Tempat Tanggal Lahir</th>
                    <th>:</th>
                    <td>{data.tempat_lahir} / {data.tanggal_lahir}</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default DetailPeserta
