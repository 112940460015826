import React, { Fragment, Component, useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  CardBody,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { Filter } from "./../Common/filters"
import { DatatablePagination } from "components/Pagination"
import Swal from "sweetalert2"
import debounce from "lodash/debounce"

export const DataTables = ({
  model,
  stringUrl,
  userAction,
  userStatus,
  addUrl,
}) => {
  const [state, setState] = useState({
    records: [],
    keyword: "",
    perPage: model.pagination.perPage,
    sortField: "",
    sortOrder: "",
    currentPage: 1,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // Display a loading modal
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    setLoading(true)
    if (stringUrl) {
      let queryParams = `?&page=${state.currentPage}&limit=${state.perPage}`
      if (addUrl) {
        queryParams += addUrl
      }
      if (state.keyword) {
        queryParams += `&keyword=${state.keyword}`
      }
      if (state.sortField) {
        queryParams += `&sort=${state.sortField}&order=${state.sortOrder}`
      }
      fetch(stringUrl + queryParams, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token_2"),
          "Content-Type": "application/json",
        },
      })
        .then(async successResponse => {
          const responseData = await successResponse.json()
          const totalRecord = responseData.data[0].total
          model.setTotalRecord(totalRecord)
          const records = responseData.data[0].data
          setState(state => ({
            ...state,
            records,
          }))
          // Close the loading modal on success
          Swal.close()
          setLoading(false)
        })
        .catch(errorResponse => {
          console.log(errorResponse)
          // Close the loading modal on error
          Swal.fire(
            "Error",
            "An error occurred while fetching the data.",
            "error"
          )
        })
    } else {
      let records = Array.from(model.records)
      records = searchRecords(records)
      records = sortRecords(records)
      records = records.splice(
        (state.currentPage - 1) * state.perPage,
        state.perPage
      )
      setState(state => ({
        ...state,
        records,
      }))
      // console.log(records);
      // Close the loading modal
      Swal.close()
      setLoading(false)
    }
  }, [
    state.keyword,
    state.perPage,
    state.sortField,
    state.sortOrder,
    state.currentPage,
  ])
  // console.log(state);

  const searchRecords = records => {
    if (!state.keyword) return records
    return records.filter(records => {
      let isMatch = false
      model.columns.forEach(column => {
        if (isMatch) return
        const value = records[column.field] || ""
        if (value.toLowerCase().includes(state.keyword)) {
          isMatch = true
        }
      })
      return isMatch
    })
  }

  const sortRecords = records => {
    if (!state.sortField) return records
    return records.sort((recordA, recordB) => {
      const valueA = recordA[state.sortField] || ""
      const valueB = recordB[state.sortField] || ""
      return valueA > valueB
        ? state.sortOrder === "desc"
          ? -1
          : +1
        : state.sortOrder === "desc"
        ? +1
        : -1
    })
  }

  const handleChange = perPage => {
    setState(state => ({
      ...state,
      perPage,
    }))
    model.setPerPage(perPage)
    handleOnChangePage(1)
    // model.setCurrentPage(1)
  }

  const debounceRef = useRef(null)

  const handleSearch = keyword => {
    setState(state => ({
      ...state,
      keyword,
    }))
  }

  const debouncedHandleSearch = debounce(keyword => {
    handleSearch(keyword)
  }, 1000)

  const handleInputChange = event => {
    const value = event.target.value.trim()
    debouncedHandleSearch(value)
  }

  const handleSort = column => {
    const { field } = column
    const sortOrder = state.sortOrder
      ? // 'asc' | 'desc'
        field === state.sortField
        ? state.sortOrder === "asc"
          ? "desc"
          : ""
        : "asc"
      : "asc"
    const sortField = sortOrder === "" ? "" : column.field
    setState(state => ({
      ...state,
      sortOrder,
      sortField,
    }))
  }

  const handleOnChangePage = currentPage => {
    setState(state => ({
      ...state,
      currentPage,
    }))
  }

  console.log(loading)

  return (
    <div className="table-responsive">
      <Col className="col-12">
        <hr />
        <Row>
          <Col lg={3}>
            <Table>
              <tbody>
                <tr>
                  <td>Show</td>
                  <td>
                    <select
                      className="form-control form-control-sm"
                      style={{ width: "100%" }}
                      aria-label="Default select example"
                      value={state.perPage}
                      onChange={event => handleChange(event.target.value)}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </select>
                  </td>
                  <td>Entries</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <div className="col-6">&nbsp;</div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm"
              id="searchTable"
              placeholder="Cari Data ..."
              onChange={handleInputChange}
            />
          </div>
        </Row>
      </Col>
      <Table className="table table-striped table-bordered">
        <thead className="table-light">
          <tr>
            <th>
              <div className="th-content">
                <span className="th-text">No</span>
              </div>
            </th>
            {model.columns.map((column, index) => {
              if (column.type === "child" || column.type === "action") {
                return (
                  <th key={index}>
                    <div className="th-content">
                      <span className="th-text">{column.header} </span>
                    </div>
                  </th>
                )
              } else {
                return (
                  <th
                    className="sorttable"
                    key={index}
                    onClick={() => handleSort(column)}
                  >
                    <div className="th-content">
                      <span className="th-text">{column.header} </span>
                      <span className="sort">
                        <em className="fas fa-sort"></em>
                      </span>
                    </div>
                  </th>
                )
              }
            })}
          </tr>
        </thead>
        <tbody>
          {loading === true ? (
            <div className=" ms-2 mt-2 fw-bold">
              <div
                role="status"
                className="spinner-border text-dark spinner-border-sm"
              >
                <span className="visually-hidden">Loading...</span>
              </div> Loading...
            </div>
          ) : (
            state.records.map((row, index) => {
              return (
                <tr key={index}>
                  <td>
                    {state.currentPage == 1 ? (
                      <div>{(index + 1) * state.currentPage}</div>
                    ) : (
                      <div>
                        {index + 1 + (state.currentPage - 1) * state.perPage}
                      </div>
                    )}
                  </td>
                  {model.columns.map((column, indexI) => {
                    const field = column.field
                    if (!field) {
                      return null // skip rendering this column if field is undefined or null
                    }
                    const keys = Array.isArray(field) ? field : [field]
                    const values = keys.map(key => row[key])

                    if (column.type === "action") {
                      return (
                        <td key={index + "" + indexI}>{userAction(values)}</td>
                      )
                    } else if (column.type === "status") {
                      const cellValue = values.length > 0 ? values[0] : null
                      return (
                        <td key={index + "" + indexI}>
                          {userStatus(cellValue)}
                        </td>
                      )
                    } else {
                      const cellValue =
                        typeof values[0] === "object"
                          ? JSON.stringify(values[0])
                          : values[0]
                      return <td key={index + "" + indexI}>{cellValue}</td>
                    }
                  })}
                </tr>
              )
            })
          )}
        </tbody>
      </Table>
      <Row>
        <Col lg={2}>
          {state.currentPage * state.perPage + 1 <=
          model.pagination.totalRecord ? (
            <div className="pt-3">
              Show from {state.currentPage * state.perPage - state.perPage + 1}{" "}
              - {state.currentPage * state.perPage} total{" "}
              {model.pagination.totalRecord}{" "}
            </div>
          ) : (
            <div className="pt-3">
              Show from {state.currentPage * state.perPage - state.perPage + 1}{" "}
              - {model.pagination.totalRecord} total{" "}
              {model.pagination.totalRecord}{" "}
            </div>
          )}
        </Col>
        <div className="col-10">
          <div className="d-flex justify-content-end">
            <DatatablePagination
              model={model.pagination}
              onChangePage={handleOnChangePage}
            />
          </div>
        </div>
      </Row>
    </div>
  )
}
