import Swal from "sweetalert2"
const CheckStatus = async (id_asesi, id_simulasi) => {
  const params = new URLSearchParams()
  params.append("id_jadwal_asesi", id_asesi)
  params.append("id_simulasi", id_simulasi)

  var myHeaders = new Headers()
  myHeaders.append(
    "Authorization",
    "Bearer " + localStorage.getItem("access_token_2")
  )

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  }

  const url = `${process.env.REACT_APP_BACKEND_URL_VERSION
    }Status-simulasi?${params.toString()}`

  fetch(url, requestOptions)
    .then(response => response.json())
    .then(result => {
      if (result.data[0] == 'selesai') {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Pengerjaan Telah Berakhir..",
        }).then(() => {
          localStorage.removeItem('access_session');
          window.history.back()
        })
      }
    })
    .catch(error => console.log(error))
}

export default CheckStatus
