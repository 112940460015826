import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import CryptoJS from "crypto-js"
import Swal from "sweetalert2"

const index = () => {
  const navigate = useNavigate()
  const { token, asesi, simulasi, tipe, proctoring } = useParams()


  const handleViewDetails = (...ids) => {
    // Navigate to page two with the id as a prop
    // navigate(`/simulasi/${ids.id}`)
    if (ids[1] === "Tipe 1") {
      navigate(`/SoalInbasket/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 2") {
      navigate(`/SoalCI/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 3") {
      navigate(`/SoalCA/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 4") {
      navigate(`/SoalPresentasi/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 5") {
      navigate(`/SoalCA/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 6") {
      navigate(`/SoalMengurutkan/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 7") {
      navigate(`/UploadFile/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 8") {
      navigate(`/SoalWawancara/${ids[0]}/${ids[2]}`)
    }
  }
  const handleChangePage = (idJadwal, idSimulasi, kategoriSimulasi) => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `Status-simulasi?status_simulasi=proses&id_jadwal_asesi=${idJadwal}&id_simulasi=${idSimulasi}`,
      requestOptions
    )
      .then(response => response.text())
      .then(result => console.log(""))
      .catch(error => console.log(""))

    handleViewDetails(idSimulasi, kategoriSimulasi, idJadwal)
  }


  Swal.fire({
    title: "Loading",
    text: "Please wait while we fetch the data...",
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    didOpen: () => {
      Swal.showLoading()
    },
  })
  const secretKey = "sonsonz" // Replace with your secret key
  const sanitizedDecryptedData = token.replace(/\_/g, '/');
  const tokenData = sanitizedDecryptedData

  const decryptedBytes = CryptoJS.AES.decrypt(tokenData, secretKey)

  const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8)
  const decryptedData = JSON.parse(decryptedText)

  const formdata = new FormData()
  formdata.append("nip", decryptedData.nip)
  formdata.append("password", decryptedData.nip)

  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  }
  const currentDateTime = new Date()
  const formattedDateTime = currentDateTime.toISOString()
  useEffect(() => {
    // Check your conditions and call navigate inside the useEffect
    if (
      !localStorage.getItem("authUser") ||
      localStorage.getItem("expired_at_2") < formattedDateTime
    ) {
      const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "auth/login";
      fetch(baseURL, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.meta.code === 200) {
            localStorage.setItem("access_token_2", data.data.access_token);
            localStorage.setItem("expired_at_2", data.data.expires_at);
            localStorage.setItem("authUser", JSON.stringify(data.data.user));
            localStorage.setItem("proctoring_status", proctoring)
            Swal.close();
            Swal.fire({
              title: "Success!",
              text: "You have been logged in.",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              // Redirect to the desired location after Swal is closed
              // navigate(`/ListSoal/` + decryptedData.id_jadwal_asesi);
              handleChangePage(asesi, simulasi, tipe);
            });
          } else {
            Swal.fire({
              title: "Error",
              text: data.data.error,
              icon: "error",
            });
          }
        })
        .catch(error => {
          console.log("error", error);
          Swal.fire({
            title: "Error",
            text: "An error occurred while logging in. Please try again later.",
            icon: "error",
          });
        });
    } else {
      // Redirect to the desired location inside the useEffect
      // navigate(`/ListSoal/` + decryptedData.id_jadwal_asesi);
      handleChangePage(asesi, simulasi, tipe);
      Swal.close();
    }
  }, []);
}

export default index
