import React, { useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { DataTables, useTable } from "../../components/DataTables"
import { Badge } from "reactstrap"
import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import StatusPeserta from "./StatusPeserta"

function JobList(props) {
  //meta title
  document.title = "Jadwal"

  const [modal, setModal] = useState(false)
  const [idModal, setIdModal] = useState()
  const [dataModal, setDataModal] = useState([])
  const [loading, setLoading] = useState(false)
  const data = props.data
  const simulasi = props.simulasi
  const idJadwal = props.idJadwal

  // validation

  const toggle = props => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const detailModal = id => {
    setIdModal(id)
    toggle()
  }

  useEffect(() => {
    if (idModal) {
      const baseURLmodal =
        process.env.REACT_APP_BACKEND_URL_VERSION +
        "Detail-simulasi?id_peserta=" +
        idModal
      setLoading(true)
      fetch(baseURLmodal, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token_2"),
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(responseData => {
          setDataModal(responseData.data[0].peserta)
          setLoading(false)
        })
        .catch(error => console.log(error))
    }
  }, [idModal])

  const userAction = row => {
    return (
      <div className="list-unstyled hstack gap-1 mb-0">
        <li>
          <Link
            to=""
            className="btn btn-sm btn-soft-primary"
            onClick={() => {
              const id_peserta = row[0]
              detailModal(id_peserta)
            }}
          >
            <i className="mdi mdi-eye-outline" id="viewtooltip" />
            <UncontrolledTooltip placement="top" target="viewtooltip">
              View
            </UncontrolledTooltip>
          </Link>
        </li>
        <li data-bs-toggle="tooltip" data-bs-placement="top">
          <Link to={"/Monitoring/" + idJadwal + "/" + row[0] + "/" + data + "/" + props.simulasi} className="btn btn-sm btn-soft-success">
            <i className=" mdi mdi-clipboard-pulse-outline" id="detailtooltip"></i>
          </Link>
        </li>
        {row[1] === "proses" && (
          <li>
            <StatusPeserta id_peserta={row[0]} id_simulasi={data} />
          </li>
        )}
        <UncontrolledTooltip placement="top" target="detailtooltip">
          Monitoring
        </UncontrolledTooltip>
      </div>
    )
  }

  const userStatus = row => {
    switch (row) {
      case "proses":
        return <Badge className="bg-info">PROSES</Badge>
      case "Belum Dikerjakan":
        return <Badge className="bg-danger">BELUM DIKERJAKAN</Badge>
      case "selesai":
        return <Badge className="bg-success">SELESAI</Badge>
    }
  }
  const columns = [
    {
      header: "Nama",
      type: "data",
      field: "nama_peserta",
    },
    {
      header: "Instansi",
      type: "data",
      field: "nama_instansi",
    },
    {
      header: "Jabatan",
      type: "data",
      field: "jabatan",
    },
    {
      header: "Status",
      type: "status",
      field: "status",
    },
    {
      header: "Action",
      type: "action",
      field: ["ja_id_peserta", "status"],

    },
  ]
  const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "Detail-simulasi"
  const posts = useTable({ columns })
  const addUrl = "&id_simulasi=" + props.data + "&id_jadwal=" + props.idJadwal

  return (
    <React.Fragment>
      <div>
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <DataTables
                model={posts}
                stringUrl={baseURL}
                userAction={userAction}
                userStatus={userStatus}
                addUrl={addUrl}
              />
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Informasi Peserta
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <div className="table-responsive">
                    <div>
                      {dataModal && loading == false ? (
                        <Table className="table mb-0">
                          <tbody>
                            <tr>
                              <th>NIP</th>
                              <th>:</th>
                              <td>{dataModal.nip}</td>
                            </tr>
                            <tr>
                              <th>Nama</th>
                              <th>:</th>
                              <td>{dataModal.nama_peserta}</td>
                            </tr>
                            <tr>
                              <th>Instansi</th>
                              <th>:</th>
                              <td>{dataModal.instansi?.nama_instansi}</td>
                            </tr>
                            <tr>
                              <th>Jabatan</th>
                              <th>:</th>
                              <td>{dataModal.jabatan}</td>
                            </tr>
                            <tr>
                              <th>Pendidikan</th>
                              <th>:</th>
                              <td>{dataModal.pendidikan}</td>
                            </tr>
                            <tr>
                              <th>Umur</th>
                              <th>:</th>
                              <td>{dataModal.umur}</td>
                            </tr>
                            <tr>
                              <th>Status</th>
                              <th>:</th>
                              <td>{dataModal.status}</td>
                            </tr>
                            <tr>
                              <th>Tempat, Tanggal Lahir</th>
                              <th>:</th>
                              <td>
                                {dataModal.tempat_lahir},{" "}
                                {dataModal.tanggal_lahir}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default JobList
