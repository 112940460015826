import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDbyhSasR7pfl97yV1tN4GdaWuI6mYJpNc",
  authDomain: "cat-ac.firebaseapp.com",
  projectId: "cat-ac",
  storageBucket: "cat-ac.appspot.com",
  messagingSenderId: "905136221327",
  appId: "1:905136221327:web:e7437a2cdd3c75df50d4fa",
  measurementId: "G-KPSRJ7B7SF"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db } 