import React, { useState, useEffect } from "react"
import ConverterSoal from "components/ConverterSoal/ConverterSoal"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

const SoalSimulasi = props => {
  const [random, setRandom] = useState(false)
  const [dataRandom, setDataRandom] = useState()
  const [data, setData] = useState([])
  const [defaultHeight, setDefaultHight] = useState("900px")
  useEffect(() => {
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "Detail-simulasi/get_asesi?id_simulasi=" +
      props.idSimulasi +
      "&id_peserta=" +
      props.idPeserta +
      "&id_jadwal=" +
      props.idJadwal
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          const id_asesi = responseData.data[0].id_jadwal_asesi
          if (id_asesi) {
            getSoal(id_asesi)
          }
        } else {
          console.log("data tidak ditemukan")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const getSoal = idAsesi => {
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "Soal-simulasi?id_simulasi=" +
      props.idSimulasi +
      "&id_asesi=" +
      idAsesi
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          console.log(responseData)
          if (responseData.data[0].data[0].msn_mks_nama_kategori === "Tipe 5") {
            setRandom(true)
            const urlRand =
              process.env.REACT_APP_BACKEND_URL_VERSION +
              "soal-random?id_peserta=" +
              props.id_peserta +
              "&id_simulasi=" +
              props.idSimulasi
            var myHeaders = new Headers()
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("access_token_2")
            )

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            }

            fetch(urlRand, requestOptions)
              .then(response => response.json())
              .then(result => {
                if (result.meta.code === 200) {
                  setDataRandom(result.data[0])
                }
              })
              .catch(error => console.log("error", error))
          }
          setData(responseData.data[0].data)
        } else {
          console.log("data tidak ditemukan")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div>
      <Row>
        <Col sm="12">
          <div
            style={{
              height: defaultHeight,
              overflow: "auto",
            }}
          >
            {dataRandom && dataRandom[0] ? (
              <>
                <ConverterSoal
                  tipeData={data[0].mfm_type}
                  linkData={"webfile/" + dataRandom[0].jr_filenama}
                />
              </>
            ) : (
              data &&
              data.map(
                (item, index) =>
                  item.mfm_type !== "Petunjuk" && (
                    <div key={index}>
                      <ConverterSoal
                        tipeData={item.mfm_type}
                        linkData={item.mfm_mf_dir}
                      />
                    </div>
                  )
              )
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SoalSimulasi
