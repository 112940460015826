import React from "react"

import { Row, Col, Card, CardBody, CardHeader, Table } from "reactstrap"
import { Link } from "react-router-dom"

const DetailJadwal = props => {
  const data = props.dataJadwal
  // console.log(data);

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <CardBody>
          <div>
            {data.length > 0 ? (
              data[0].jadwal.map(item => (
                <React.Fragment key={item.id_mjadwal}>
                  {/* <CardHeader className="bg-primary p-3 rounded">
                    <h5 className="text-white">Informasi Jadwal [{item.mj_type} - {item.mj_tglmulai} - {item.mj_tglselesai}]</h5>
                  </CardHeader> */}
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        <tr>
                          <th>Nomer Registrasi</th>
                          <th>:</th>
                          <td>{item.mj_noreg} </td>
                        </tr>
                        <tr>
                          <th>Password</th>
                          <th>:</th>
                          <td>{item.mj_pass}</td>
                        </tr>
                        <tr>
                          <th>Tanggal Mulai</th>
                          <th>:</th>
                          <td>{item.mj_tglmulai}</td>
                        </tr>
                        <tr>
                          <th>Tanggal Selesai</th>
                          <th>:</th>
                          <td>{item.mj_tglselesai}</td>
                        </tr>
                        <tr>
                          <th>Target Jabatan</th>
                          <th>:</th>
                          <td>{item.target_jabatan.target_nama}</td>
                        </tr>
                        <tr>
                          <th>Instansi</th>
                          <th>:</th>
                          <td>{item.instansi.nama_instansi}</td>
                        </tr>
                        <tr>
                          <th>Lokasi</th>
                          <th>:</th>
                          <td>{item.mj_lokasi}</td>
                        </tr>
                        <tr>
                          <th>PIC</th>
                          <th>:</th>
                          <td>{item.mj_pic_name}</td>
                        </tr>
                        {/* <tr>
                          <th>Tempat Skala</th>
                          <th>:</th>
                          <td>{item.mj_id_skala}</td>
                        </tr> */}
                        <tr>
                          <th>Tipe Jadwal</th>
                          <th>:</th>
                          <td>{item.mj_type}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </CardBody>
      </Card>
      
    </React.Fragment>
  )
}
export default DetailJadwal
