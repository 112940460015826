import React, { useState, useEffect, useRef } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardHeader,
  Table,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import classnames from "classnames"
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import Select from "react-select"
import Swal from "sweetalert2"
import Presentation from "components/PresentationGenerator/Presentation"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import SoalSimulasi from "./SoalSimulasi"

const MemoizedPresentation = React.memo(Presentation)

const LogSimulasi = props => {
  const user = JSON.parse(localStorage.getItem("authUser"))
  const data = props.data
  const [activeTab, setActiveTab] = useState("1")
  const [filePDF, setFilePdf] = useState()
  const [filePPT, setFilePPT] = useState()
  const [multiSoal, setMultiSoal] = useState([])
  const [multiSoalLog, setMultiSoalLog] = useState([])
  const [accordionOpen, setAccordionOpen] = useState([])
  const [modal, setModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState()
  const [selectedOptionMultiSoal, setSelectedOptionMultiSoal] = useState()
  const [selectedOptionUrut, setSelectedOptionUrut] = useState()
  const reportTemplateRef = useRef(null)

  const handleExport = filename => {
    const preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>"
    const postHtml = "</body></html>"
    const html = preHtml + reportTemplateRef.current.innerHTML + postHtml

    const blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    })

    const url =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html)

    filename = filename ? filename + ".doc" : "document.doc"

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      const downloadLink = document.createElement("a")
      document.body.appendChild(downloadLink)
      downloadLink.href = url
      downloadLink.download = filename
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }

  const formatDate = dateString => {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
      return "" // Handle invalid date string
    }
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }
    const formattedDate = date
      .toLocaleDateString(undefined, options)
      .replace(/\//g, "-")
      .replace(",", "")
    return formattedDate
  }

  const options = data.map(item => ({
    label: formatDate(item.created_at),
    value: item.value,
    urut: item.value_urut,
  }))

  const handleChange = selected => {
    let multiSoal
    try {
      multiSoal = JSON.parse(selected.value)
    } catch (error) {
      multiSoal = selected.value
    }
    setSelectedOptionMultiSoal(multiSoal)
    setSelectedOption(selected.value)
    setSelectedOptionUrut(selected.urut)
  }

  const customStyles = {
    option: provided => ({
      ...provided,
      backgroundColor: "white",
    }),
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggle = props => {
    setSelectedOption(null)
    setSelectedOptionUrut(null)
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const toggleAccordion = index => {
    const updatedAccordionOpen = [...accordionOpen]
    updatedAccordionOpen[index] = !updatedAccordionOpen[index]
    setAccordionOpen(updatedAccordionOpen)
  }
  //url ppt
  const url = process.env.REACT_APP_BACKEND_URL
  let count = +1
  useEffect(() => {
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/jawaban-peserta?id_peserta=` +
      props.id_peserta +
      `&id_simulasi=` +
      props.id_simulasi +
      `&tipe=` +
      props.tipe +
      `&id_jadwal=` +
      props.id_jadwal
    fetch(baseURLGet, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          if (props.tipe == "Tipe 7") {
            setFilePdf(responseData.data[0])
          } else if (props.tipe == "Tipe 4") {
            setFilePPT(responseData.data[0].dir)
          } else if (props.tipe == "Tipe 2") {
            const data = JSON.parse(props.jawaban)
            setMultiSoal(data)
            //get monitoring log tipe 2
            var myHeaders = new Headers()
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("access_token_2")
            )

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            }

            const baseURLlogs =
              process.env.REACT_APP_BACKEND_URL_VERSION +
              "monitoring/log?id_peserta=" +
              props.id_peserta +
              "&id_simulasi=" +
              props.id_simulasi +
              "&tipe=" +
              props.tipe +
              `&id_jadwal=` +
              props.id_jadwal

            fetch(baseURLlogs, requestOptions)
              .then(response => response.json())
              .then(result => {
                setMultiSoalLog(result.data[0])
                // console.log(result.data[0])
              })
              .catch(error => console.log("error"))
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const handleRollback = () => {
    Swal.fire({
      title: "KONFIRMASI",
      text: "Anda yakin untuk melakukan rollback jawaban ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }) // Display loading state

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

        const raw = JSON.stringify({
          "id_peserta": props.id_peserta,
          "jawaban": selectedOption,
          "jawaban_mengurutkan": selectedOptionUrut,
          "id_simulasi": props.id_simulasi,
          "id_jadwal": props.id_jadwal
        });

        const requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        const baseURLGet =
          process.env.REACT_APP_BACKEND_URL_VERSION +
          "jawaban/jawaban-peserta";

        fetch(baseURLGet, requestOptions)
          .then(response => response.text())
          .then(result => {
            Swal.close() // Close loading state
            Swal.fire("Success", "Jawaban berhasil dirollback", "success").then(
              () => {
                toggle(
                  window.location.reload()
                )
              }
            )
          })
          .catch(error => {
            console.log("error", error)
            Swal.close() // Close loading state
            Swal.fire("Error", "Jawaban gagal dirollback", "error")
          })
      }
    })
  }

  return (
    <React.Fragment>
      {props.status == "proses"
        ? user.role_id !== 20 && (
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-3">Monitoring Simulasi</CardTitle>
              <Nav pills className="navtab-bg nav-justified col-12">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => toggleTab("1")}
                  >
                    Simulasi
                  </NavLink>
                </NavItem>
                <NavItem>
                  {props.tipe != "Tipe 7" && props.tipe != "Tipe 4" && (
                    <Button
                      className="btn btn-danger"
                      onClick={() => toggle()}
                    >
                      Rollback
                    </Button>
                  )}
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="1">
                  <Row>
                    <Col lg={12}>
                      <div className="accordion" id="accordion-1">
                        {props.tipe === "Tipe 7" ? (
                          filePDF && filePDF.length > 0 ? (
                            filePDF.map((item, index) => (
                              <div className="accordion-item" key={index}>
                                <h2
                                  className="accordion-header"
                                  id={`heading-${index}`}
                                >
                                  <button
                                    className={classnames(
                                      "accordion-button",
                                      "fw-medium",
                                      {
                                        collapsed: !accordionOpen[index],
                                      }
                                    )}
                                    type="button"
                                    onClick={() => toggleAccordion(index)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {formatDate(item.created_at)}
                                  </button>
                                </h2>

                                <Collapse
                                  isOpen={accordionOpen[index]}
                                  className="accordion-collapse collapse"
                                  id={`collapse-${index}`}
                                  aria-labelledby={`heading-${index}`}
                                >
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      <strong className="text-dark">
                                        Tipe 7
                                      </strong>{" "}
                                      <div>
                                        <DocViewer
                                          documents={[
                                            {
                                              uri: url + item.juf_file_dir,
                                            },
                                          ]}
                                          pluginRenderers={DocViewerRenderers}
                                          style={{ height: 900 }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                            ))
                          ) : (
                            <p>No files available.</p>
                          )
                        ) : props.tipe === "Tipe 4" ? (
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={`heading-${1}`}
                            >
                              <button
                                className={classnames(
                                  "accordion-button",
                                  "fw-medium",
                                  {
                                    collapsed: !accordionOpen[1],
                                  }
                                )}
                                type="button"
                                onClick={() => toggleAccordion(1)}
                                style={{ cursor: "pointer" }}
                              >
                                Jawaban Presentasi
                              </button>
                            </h2>

                            <Collapse
                              isOpen={accordionOpen[1]}
                              className="accordion-collapse collapse"
                              id={`collapse-${1}`}
                              aria-labelledby={`heading-${1}`}
                            >
                              <div className="accordion-body">
                                <div className="text-muted">
                                  <strong className="text-dark">
                                    Tipe 4
                                  </strong>{" "}
                                  <div style={{ height: 500 }}>
                                    <MemoizedPresentation
                                      dir={filePPT}
                                      id_peserta={props.id_peserta}
                                      id_simulasi={props.id_simulasi}
                                      id_jadwal={props.id_jadwal}
                                      mode={"view"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        ) : props.tipe === "Tipe 6" ? (
                          data.map((item, index) => (
                            <div className="accordion-item" key={index}>
                              <h2
                                className="accordion-header"
                                id={`heading-${index}`}
                              >
                                <button
                                  className={classnames(
                                    "accordion-button",
                                    "fw-medium",
                                    { collapsed: !accordionOpen[index] }
                                  )}
                                  type="button"
                                  onClick={() => toggleAccordion(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {formatDate(item.created_at)}
                                </button>
                              </h2>

                              <Collapse
                                isOpen={accordionOpen[index]}
                                className="accordion-collapse collapse"
                                id={`collapse-${index}`}
                                aria-labelledby={`heading-${index}`}
                              >
                                <div className="accordion-body">
                                  <div className="text-muted">
                                    <strong className="text-dark">
                                      {item.tipe}
                                    </strong>{" "}
                                    <Row>
                                      <Col xl="6">
                                        <h4 className="fw-bold fs-5">
                                          Jawaban Text
                                        </h4>
                                        <Form>
                                          <CKEditor
                                            editor={ClassicEditor}
                                            data={item.value}
                                            disabled={true}
                                            config={{
                                              toolbar: [
                                                "bold",
                                                "italic",
                                                "bulletedList",
                                                "|",
                                                "numberedList",
                                                "alignment",
                                                "link", // Add the "link" option to include the Link plugin
                                              ],
                                              removePlugins: ["Heading"],
                                              isReadOnly: true,
                                            }}
                                          />
                                        </Form>
                                      </Col>
                                      <Col xl="6">
                                        <h4 className="fw-bold fs-5">
                                          Jawaban mengurutkan
                                        </h4>
                                        <Table className="table mb-0">
                                          <tbody>
                                            {(item.value_urut || []).map(
                                              (item, index) => (
                                                <tr key={index}>
                                                  <th
                                                    scope="row"
                                                    className="drag-pointer"
                                                  >
                                                    {item}
                                                  </th>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </Table>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          ))
                        ) : props.tipe === "Tipe 2" ? (
                          multiSoalLog && multiSoalLog.length > 0 ? (
                            multiSoalLog.map((item, index) => (
                              <div className="accordion-item" key={index}>
                                <h2
                                  className="accordion-header"
                                  id={`heading-${index}`}
                                >
                                  <button
                                    className={classnames(
                                      "accordion-button",
                                      "fw-medium",
                                      {
                                        collapsed: !accordionOpen[index],
                                      }
                                    )}
                                    type="button"
                                    onClick={() => toggleAccordion(index)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {formatDate(item.created_at)}
                                  </button>
                                </h2>

                                <Collapse
                                  isOpen={accordionOpen[index]}
                                  className="accordion-collapse collapse"
                                  id={`collapse-${index}`}
                                  aria-labelledby={`heading-${index}`}
                                >
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      <strong className="text-dark">
                                        Tipe 2
                                      </strong>{" "}
                                      <div>
                                        <Table className="table mb-0">
                                          <tbody>
                                            {Object.keys(item.value).map(
                                              key => (
                                                <tr key={key}>
                                                  <th>
                                                    Soal : {key}:{" "}
                                                  </th>
                                                  <td>
                                                    <Form>
                                                      <CKEditor
                                                        editor={ClassicEditor}
                                                        data={item.value[key]}
                                                        disabled={true}
                                                        config={{
                                                          toolbar: [
                                                            "bold",
                                                            "italic",
                                                            "bulletedList",
                                                            "|",
                                                            "numberedList",
                                                            "alignment",
                                                            "link", // Add the "link" option to include the Link plugin
                                                          ],
                                                          removePlugins: [
                                                            "Heading",
                                                          ],
                                                          isReadOnly: true,
                                                        }}
                                                      />
                                                    </Form>
                                                  </td>
                                                </tr>
                                              )
                                            )}{" "}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                            ))
                          ) : (
                            <p>No data available.</p>
                          )
                        ) : (
                          data.map((item, index) => (
                            <div className="accordion-item" key={index}>
                              <h2
                                className="accordion-header"
                                id={`heading-${index}`}
                              >
                                <button
                                  className={classnames(
                                    "accordion-button",
                                    "fw-medium",
                                    { collapsed: !accordionOpen[index] }
                                  )}
                                  type="button"
                                  onClick={() => toggleAccordion(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {formatDate(item.created_at)}
                                </button>
                              </h2>

                              <Collapse
                                isOpen={accordionOpen[index]}
                                className="accordion-collapse collapse"
                                id={`collapse-${index}`}
                                aria-labelledby={`heading-${index}`}
                              >
                                <div className="accordion-body">
                                  <div className="text-muted">
                                    <strong className="text-dark">
                                      {item.tipe}
                                    </strong>{" "}
                                    <Form>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={item.value}
                                        disabled={true}
                                        config={{
                                          toolbar: [
                                            "bold",
                                            "italic",
                                            "bulletedList",
                                            "|",
                                            "numberedList",
                                            "alignment",
                                            "link", // Add the "link" option to include the Link plugin
                                          ],
                                          removePlugins: ["Heading"],
                                          isReadOnly: true,
                                        }}
                                      />
                                    </Form>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          ))
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        )
        : props.status == "selesai" && (
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-3">Monitoring Simulasi</CardTitle>
              <Nav pills className="navtab-bg nav-justified col-12">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => toggleTab("1")}
                  >
                    Jawaban
                  </NavLink>
                </NavItem>
                {props.tipe === "Tipe 5" && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => toggleTab("2")}
                    >
                      Soal
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  {props.tipe != "Tipe 7" && filePPT == null && (
                    <>
                      <Button
                        className="btn btn-danger mx-1"
                        onClick={() => toggle()}
                      >
                        Rollback
                      </Button>
                      <Button
                        className="btn btn-primary mx-1"
                        onClick={() => handleExport()}
                      >
                        Generate document
                      </Button>
                    </>
                  )}
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="1">
                  <div>
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col sm="12">
                            {props.tipe === "Tipe 4" ? (
                              <div style={{ height: 500 }}>
                                {filePPT ? (
                                  <MemoizedPresentation
                                    dir={filePPT}
                                    id_peserta={props.id_peserta}
                                    id_simulasi={props.id_simulasi}
                                    id_jadwal={props.id_jadwal}
                                    mode={"view"}
                                  />
                                ) : (
                                  <Form>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={props.jawaban}
                                      disabled={true}
                                      config={{
                                        toolbar: [
                                          "bold",
                                          "italic",
                                          "bulletedList",
                                          "|",
                                          "numberedList",
                                          "alignment",
                                          "link", // Add the "link" option to include the Link plugin
                                        ],
                                        removePlugins: ["Heading"],
                                        isReadOnly: true,
                                      }}
                                    />
                                  </Form>
                                )}
                              </div>
                            ) : props.tipe === "Tipe 7" ? (
                              filePDF && filePDF.length > 0 ? (
                                filePDF.map((item, index) => (
                                  <div className="accordion-item" key={index}>
                                    <h2
                                      className="accordion-header"
                                      id={`heading-${index}`}
                                    >
                                      <button
                                        className={classnames(
                                          "accordion-button",
                                          "fw-bold",
                                          "mb-3",
                                          {
                                            collapsed: !accordionOpen[index],
                                          }
                                        )}
                                        type="button"
                                        onClick={() => toggleAccordion(index)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        File Uploud {count++}
                                      </button>
                                    </h2>

                                    <Collapse
                                      isOpen={accordionOpen[index]}
                                      className="accordion-collapse collapse"
                                      id={`collapse-${index}`}
                                      aria-labelledby={`heading-${index}`}
                                    >
                                      <div className="accordion-body">
                                        <div className="text-muted">
                                          <div>
                                            <DocViewer
                                              documents={[
                                                {
                                                  uri:
                                                    url + item.juf_file_dir,
                                                },
                                              ]}
                                              pluginRenderers={
                                                DocViewerRenderers
                                              }
                                              style={{ height: 900 }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>
                                ))
                              ) : (
                                <p>No files available.</p>
                              )
                            ) : props.tipe === "Tipe 6" ? (
                              <div
                                ref={reportTemplateRef}
                                style={{ width: "100%" }}
                              >
                                <Row>
                                  <Col xl="6">
                                    <h4 className="fw-bold fs-5">
                                      Jawaban Text
                                    </h4>
                                    <Form>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={props.jawaban}
                                        disabled={true}
                                        config={{
                                          toolbar: [
                                            "bold",
                                            "italic",
                                            "bulletedList",
                                            "|",
                                            "numberedList",
                                            "alignment",
                                            "link", // Add the "link" option to include the Link plugin
                                          ],
                                          removePlugins: ["Heading"],
                                          isReadOnly: true,
                                        }}
                                      />
                                    </Form>
                                  </Col>
                                  <Col xl="6">
                                    <h4 className="fw-bold fs-5">
                                      Jawaban mengurutkan
                                    </h4>
                                    <Table className="table mb-0">
                                      <tbody>
                                        {(props.jawabanUrut || []).map(
                                          (item, index) => (
                                            <tr key={index}>
                                              <th
                                                scope="row"
                                                className="drag-pointer"
                                              >
                                                {item}
                                              </th>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Row>
                              </div>
                            ) : props.tipe === "Tipe 2" ? (
                              <div
                                ref={reportTemplateRef}
                                style={{ width: "100%" }}
                              >
                                <Table
                                  className="table mb-0"
                                  style={{ width: "100%" }}
                                >
                                  <tbody>
                                    {Object.keys(multiSoal).map(key => (
                                      <tr key={key}>
                                        <th>Soal ke-{parseInt(key)}: </th>
                                        <td>
                                          <Form>
                                            <CKEditor
                                              editor={ClassicEditor}
                                              data={multiSoal[key]}
                                              disabled={true}
                                              config={{
                                                toolbar: [
                                                  "bold",
                                                  "italic",
                                                  "bulletedList",
                                                  "|",
                                                  "numberedList",
                                                  "alignment",
                                                  "link", // Add the "link" option to include the Link plugin
                                                ],
                                                removePlugins: ["Heading"],
                                                isReadOnly: true,
                                              }}
                                            />
                                          </Form>
                                        </td>
                                      </tr>
                                    ))}{" "}
                                  </tbody>
                                </Table>
                              </div>
                            ) : (
                              <div
                                ref={reportTemplateRef}
                                style={{ width: "100%" }}
                              >
                                <Form>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={props.jawaban}
                                    disabled={true}
                                    config={{
                                      toolbar: [
                                        "bold",
                                        "italic",
                                        "bulletedList",
                                        "|",
                                        "numberedList",
                                        "alignment",
                                        "link", // Add the "link" option to include the Link plugin
                                      ],
                                      removePlugins: ["Heading"],
                                      isReadOnly: true,
                                    }}
                                  />
                                </Form>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                    </Card>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div>
                    <Card>
                      <CardBody>
                        <SoalSimulasi
                          idSimulasi={props.id_simulasi}
                          idPeserta={props.id_peserta}
                          idJadwal={props.id_jadwal}
                        />
                      </CardBody>
                    </Card>
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        )}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          Rollback Jawaban Peserta
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-12 my-1">
              <Select
                options={options}
                onChange={handleChange}
                styles={customStyles}
                placeholder="Select date rollback"
                value={selectedOption}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 my-1">
              <Card>
                <CardHeader>Jawaban</CardHeader>
                <CardBody>
                  {selectedOption ? (
                    <div>
                      {props.tipe === "Tipe 6" ? (
                        <Row>
                          <Col xl="6">
                            <h4 className="fw-bold fs-5">Jawaban Text</h4>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: selectedOption,
                              }}
                            />
                          </Col>
                          <Col xl="6">
                            <h4 className="fw-bold fs-5">
                              Jawaban mengurutkan
                            </h4>
                            <Table className="table mb-0">
                              <tbody>
                                {(selectedOptionUrut || []).map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <th scope="row" className="drag-pointer">
                                        {item}
                                      </th>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      ) : props.tipe === "Tipe 2" ? (
                        <div>
                          <Table
                            className="table mb-0"
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              {Object.keys(selectedOptionMultiSoal).map(key => (
                                <tr key={key}>
                                  <th>Soal ke-{parseInt(key)}: </th>
                                  <td>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: selectedOptionMultiSoal[key],
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}{" "}
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        <div className="accordion-body">
                          <div className="text-muted">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: selectedOption,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>Pilih log terlebih dahulu</>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 my-1 d-flex justify-content-end">
              {selectedOption && (
                <Button
                  className="btn btn-danger"
                  onClick={() => handleRollback()}
                >
                  Rollback
                </Button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default LogSimulasi
