import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Nav,
  NavItem,
  NavLink,
  CardText,
  TabContent,
  TabPane,
  CardHeader,
} from "reactstrap"

// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

const FormEditors = () => {
  //meta title
  document.title = "Soal Simulasi"
  const [activeTab1, setactiveTab1] = useState("5")
  const [activeCard, setactiveCard] = useState("6")
  const [activeCardEditor, setactiveCardEditor] = useState("6")
  const [isHidden, setIsHidden] = useState(false);
  const [isHiddenEditor, setIsHiddenEditor] = useState(false);

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  function expand() {
    if (activeCard == "6") {
      setactiveCard("12")
      setIsHiddenEditor(true);
    } else {
      setactiveCard("6")
      setIsHiddenEditor(false);
    }
  }

  function expandEditor() {
    if (activeCardEditor == "6") {
      setactiveCardEditor("12")
      setIsHidden(true)
    } else {
      setactiveCardEditor("6")
      setIsHidden(false)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Simulasi" breadcrumbItem="Group Discussion" />
          <Row>
            <Card>
              <CardBody>
                <div className="bg-primary bg-soft p-3 rounded">
                  <h5 className="font-size-14 text-primary mb-0">
                    <i className="bx bxs-timer me-2" /> 01 : 30 : 59{" "}
                    <span className="float-end">On Progress</span>
                  </h5>
                </div>
              </CardBody>
            </Card>
          </Row>
          <Row>
            {isHidden ? null :
              <Col lg={activeCard}>
                <Card>
                  <CardHeader className="bg-light">
                    <Row>
                      <Col className="col-8">
                        <Nav pills className="navtab-bg nav-justified">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTab1 === "5",
                              })}
                              onClick={() => {
                                toggle1("5")
                              }}
                            >
                              Materi GD
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTab1 === "6",
                              })}
                              onClick={() => {
                                toggle1("6")
                              }}
                            >
                              Jawaban CA
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col className="d-flex aling-item-start justify-content-end">
                        <button
                          type="button"
                          onClick={() => {
                            expand()
                          }}
                          className="btn noti-icon "
                        >
                          <i className="bx bx-fullscreen" />
                        </button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <TabContent activeTab={activeTab1} className="p-3 text-muted">
                      <TabPane tabId="5">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              Raw denim you probably haven&apos;t heard of them
                              jean shorts Austin. Nesciunt tofu stumptown aliqua,
                              retro synth master cleanse. Mustache cliche tempor,
                              williamsburg carles vegan helvetica. Reprehenderit
                              butcher retro keffiyeh dreamcatcher synth. Cosby
                              sweater eu banh mi, qui irure terry richardson ex
                              squid. Aliquip placeat salvia cillum iphone. Seitan
                              aliquip quis cardigan american apparel, butcher
                              voluptate nisi qui.
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="6">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              Food truck fixie locavore, accusamus
                              mcsweeney&apos;s marfa nulla single-origin coffee
                              squid. Exercitation +1 labore velit, blog sartorial
                              PBR leggings next level wes anderson artisan four
                              loko farm-to-table craft beer twee. Qui photo booth
                              letterpress, commodo enim craft beer mlkshk aliquip
                              jean shorts ullamco ad vinyl cillum PBR. Homo
                              nostrud organic, assumenda labore aesthetic magna
                              delectus mollit. Keytar helvetica VHS salvia yr,
                              vero magna velit sapiente labore stumptown. Vegan
                              fanny pack odio cillum wes anderson 8-bit.
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            }
            {isHiddenEditor ? null :
              <Col lg={activeCardEditor}>
                <Card>
                  <CardHeader className="bg-light">
                    <Row>
                      <Col>
                        <CardTitle className="h2">Jawaban</CardTitle>
                      </Col>

                      <Col className=" col-4 text-end">
                        <button type="button" className="btn btn-soft-primary">
                          Buka Kalkulator
                        </button>
                      </Col>
                      <Col className="col-2 text-end">
                        <button
                          type="button"
                          onClick={() => {
                            expandEditor()
                          }}
                          className="btn noti-icon "
                        >
                          <i className="bx bx-fullscreen" />
                        </button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form method="post">
                      <CKEditor
                        editor={ClassicEditor}
                        data="<p>
                      Raw denim you probably haven't heard of them
                            jean shorts Austin. Nesciunt tofu stumptown aliqua,
                            retro synth master cleanse. Mustache cliche tempor,
                            williamsburg carles vegan helvetica. Reprehenderit
                            butcher retro keffiyeh dreamcatcher synth. Cosby
                            sweater eu banh mi, qui irure terry richardson ex
                            squid. Aliquip placeat salvia cillum iphone. Seitan
                            aliquip quis cardigan american apparel, butcher
                            voluptate nisi qui.
                      </p>"
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor)
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                        }}
                      />
                    </Form>
                  </CardBody>
                </Card>
              </Col>

            }

          </Row>
          <Row>
            <Card>
              <CardBody>
                <div className="d-flex flex-row-reverse bd-highlight">
                  <Link to="/ListSoal">
                    <button className="btn btn-soft-success ms-2 bd-highlight">
                      Selesai
                    </button>
                  </Link>
                  <button className="btn btn-soft-primary  bd-highlight">
                    Simpan Jawaban
                  </button>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormEditors
