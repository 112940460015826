import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { logoutUser } from "../../store/actions";
import Swal from "sweetalert2"
//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("authUser"))
  useEffect(() => {
   
  if (userData.id_peserta) {
    const requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    }
    const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + `auth/logout?id_peserta=`+ userData.id_peserta
    fetch(baseURL, requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.meta.code == 200) {
          Swal.fire({
            title: "Success!",
            text: data.data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            dispatch(logoutUser(history));
          });

        } else {
          Swal.fire({
            title: "Error",
            text: data.data.error,
            icon: "error",
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
        });
      });
  } else {
    dispatch(logoutUser(history));
  }  
  }, [dispatch, history]);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);