import React, { useEffect, useMemo, useState, Fragment } from "react"
import { Link, useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Status } from "./status"
import { DataTables, useTable } from "../../../components/DataTables"
import { Badge } from "reactstrap"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

function DataJadwal() {
  //meta title
  const navigate = useNavigate()
  const handleViewDetails = id => {
    // Navigate to page two with the id as a prop
    navigate(`/ListSoal/${id}`)
    // console.log(id)
  }

  const userStatus = row => {
    switch (row) {
      case "on_going":
        return <Badge className="bg-info">On Going</Badge>
      case "on_schedule":
        return <Badge className="bg-danger">On Schedule</Badge>
      case "ready to test":
        return <Badge className="bg-success">Ready</Badge>
      case "done":
        return <Badge className="bg-danger">Done</Badge>
    }
  }

  const userAction = row => {
    switch (row[0]) {
      case "on_going":
        return (
          <Fragment>
            <div className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <button
                  className="btn btn-sm btn-soft-success"
                  onClick={() => handleViewDetails(row[1])}
                >
                  <i
                    className=" mdi mdi-clipboard-pulse-outline"
                    id={`detailtooltip-${row[1]}`}
                  ></i>
                  <span className="ms-1">Mulai Mengerjakan</span>
                </button>
              </li>
              <UncontrolledTooltip placement="top" target={`detailtooltip-${row[1]}`}>
                Detail Jadwal
              </UncontrolledTooltip>
            </div>
          </Fragment>
        )
      case "on_schedule":
        return (
          <Fragment>
            <div className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <button
                  className="btn btn-sm btn-primary"
                  disabled="disabled"
                >
                  <i
                    className=" mdi mdi-clipboard-pulse-outline"
                    id={`detailtooltip-${row[1]}`}
                  ></i>
                  <span className="ms-1">Coming Soon</span>
                </button>
              </li>
              <UncontrolledTooltip placement="top" target={`detailtooltip-${row[1]}`}>
                Detail Jadwal
              </UncontrolledTooltip>
            </div>
          </Fragment>
        )
      case "ready to test":
        return <Badge className="bg-success">Ready</Badge>
      case "done":
        return (
          <Fragment>
            <div className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleViewDetails(row[1])}
                  disabled={true}
                >
                  <i
                    className=" mdi mdi-clipboard-pulse-outline"
                    id={`detailtooltip-${row[1]}`}
                  ></i>
                  <span className="ms-1">Jadwal tidak tersedia</span>
                </button>
              </li>
              <UncontrolledTooltip placement="top" target={`detailtooltip-${row[1]}`}>
                Jadwal tidak tersedia
              </UncontrolledTooltip>
            </div>
          </Fragment>
        )
    }
  }

  const columns = [
    {
      header: "Kode Test",
      type: "data",
      field: "mj_noreg",
    },
    {
      header: "Tanggal Mulai",
      type: "data",
      field: "mj_tglmulai",
    },
    {
      header: "Tanggal Selesai",
      type: "data",
      field: "mj_tglselesai",
    },
    {
      header: "Target Jabatan",
      type: "data",
      field: "target_nama",
    },
    {
      header: "Status",
      type: "status",
      field: "mj_status_jadwal",
    },
    {
      header: "Action",
      type: "action",
      field: ["mj_status_jadwal", "id_jadwal_asesi"],
    },
  ]

  const posts = useTable({ columns })

  const userData = JSON.parse(localStorage.getItem("authUser"))

  const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "Jadwal-Aktif/"

  const addUrl = "&nip_peserta=" + userData.nip

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody className="border-bottom">
              <div className="d-flex align-items-center">
                <h5 className="mb-0 card-title flex-grow-1">Jadwal</h5>
              </div>

              <DataTables
                model={posts}
                stringUrl={baseURL}
                userAction={userAction}
                userStatus={userStatus}
                addUrl={addUrl}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DataJadwal
