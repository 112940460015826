import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// import images
import profile from "assets/images/users/7309681.jpg";
import logo from "assets/images/logobpkp.png";

import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from 'sweetalert2';

const Login = props => {

  //meta title
  document.title = "Login";
  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/login') {
      if (localStorage.getItem("authUser")) {
        localStorage.removeItem('authUser');
      }
    }
  }, [location]);

  const handleSubmit = (values) => {
    Swal.fire({
      title: "Loading...",
      text: "Please wait while we process your request",
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const formdata = new FormData();
    formdata.append("nip", values.email);
    formdata.append("password", values.password);

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "auth/login"
    fetch(baseURL, requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.meta.code == 200) {
          localStorage.setItem('access_token_2', data.data.access_token)
          localStorage.setItem('expired_at_2', data.data.expires_at)
          localStorage.setItem('authUser', JSON.stringify(data.data.user));

          Swal.fire({
            title: "Success!",
            text: "You have been logged in.",
            icon: "success",
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            // Do something after the Swal is closed, e.g. redirect to dashboard
            let userRole
            if (data.data.user.role) {
              navigate(`/dashboard`);
            } else {
              navigate(`/Profile`);
            }
          });

        } else {
          Swal.fire({
            title: "Error",
            text: data.data.error,
            icon: "error",
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        Swal.fire({
          title: "Error",
          text: "An error occurred while logging in. Please try again later.",
          icon: "error",
        });
      });
  };




  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: handleSubmit,
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue assessment BPKP.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end mb-3">
                      <img src={logo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={validation.handleSubmit}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">NIP</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter NIP"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          value={validation.values.password || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
