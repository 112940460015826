import React, { Component } from "react"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CaptureRecords from '../../components/CaptureRecord/index'
class CountdownTimer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: props.status,
      timeLeft: props.startTime * 60, // in minutes
      id_peserta: props.id_peserta,
      id_simulasi: props.id_simulasi,
      id_jadwal_asesi: props.id_jadwal_asesi,
      type: props.type,
      jawaban: props.jawaban,
      datadrag: props.datadrag,
      id_jadwal: props.id_jadwal,
      isOnline: props.isOnline,
      timeOut: false,
    }
    this.interval = null
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jawaban !== this.props.jawaban) {
      this.setState({ jawaban: this.props.jawaban })
    }
    if (prevProps.datadrag !== this.props.datadrag) {
      this.setState({ datadrag: this.props.datadrag })
    }

    if (prevProps.status !== this.props.status) {
      this.setState({ status: this.props.status }, () => {
        if (this.state.status === true) {
          this.startTimer();
        } else {
          this.stopTimer();
        }
      });
    }

    if (prevProps.isOnline !== this.props.isOnline) {
      this.setState({ isOnline: this.props.isOnline }, () => {
        if (this.state.isOnline === true) {
          this.startTimer();
        } else {
          this.stopTimer();
        }
      });
    }
  }

  startTimer() {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.setState(prevState => {
          const newTimeLeft = prevState.timeLeft - 1;
          if (newTimeLeft <= 0) {
            this.stopTimer();
            this.handleTimeUp();
            this.handledelete();
            Swal.fire({
              title: "Time Out",
              text: "Waktu habis jawaban akan disimpan secara otomatis",
              timer: 5000,
              timerProgressBar: true,
              showConfirmButton: false,
              onBeforeOpen: () => {
                Swal.showLoading();
              },
              onClose: () => {
                console.log("Timer alert closed");
              },
            }).then(result => {
              if (result.dismiss === Swal.DismissReason.timer) {
                this.handleUpdateStatus();
                console.log("Timer expired");
              }
            });
          }
          return { timeLeft: newTimeLeft };
        });
      }, 1000);

      setInterval(() => {
        this.setState(prevState => {
          const newTimeLeft = prevState.timeLeft - 1;
          if (newTimeLeft > 0) {
            this.handleSaveJawaban();
          }
        });
      }, 30000);
    }
  }
  componentDidMount() {
    const { status } = this.state;
    if (status === true) {
      this.interval = setInterval(() => {
        this.setState(prevState => {
          const newTimeLeft = prevState.timeLeft - 1;
          if (newTimeLeft <= 0) {
            clearInterval(this.interval);
            this.handleTimeUp();
            this.handledelete();
            Swal.fire({
              title: "Time Out",
              text: "Waktu habis jawaban akan disimpan secara otomatis",
              timer: 5000,
              timerProgressBar: true,
              showConfirmButton: false,
              onBeforeOpen: () => {
                Swal.showLoading();
              },
              onClose: () => {
                console.log("Timer alert closed");
              },
            }).then(result => {
              if (result.dismiss === Swal.DismissReason.timer) {
                this.handleUpdateStatus();
                console.log("Timer expired");

              }
            });
          }
          return { timeLeft: newTimeLeft };
        });
      }, 1000);

      setInterval(() => {
        this.setState(prevState => {
          const newTimeLeft = prevState.timeLeft - 1;
          if (newTimeLeft > 0) {
            this.handleSaveJawaban();
          }
        });
      }, 30000);
    }
  }


  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleTimeUp() {
    // localStorage.removeItem('access_session');
    // const { id_peserta, type, jawaban, id_simulasi, id_jadwal } = this.state

    // const baseURL =
    //   process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/jawaban-peserta"

    // var formdata = new FormData()
    // formdata.append("id_peserta", id_peserta)
    // formdata.append("id_simulasi", id_simulasi)
    // formdata.append("jawaban", jawaban)
    // formdata.append("type", type)
    // formdata.append("id_jadwal", id_jadwal)

    // console.log(formdata)
    // var myHeaders = new Headers()
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer " + localStorage.getItem("access_token_2")
    // )

    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // }

    // fetch(baseURL, requestOptions)
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log("error", error))
  }

  handleSaveJawaban() {
    const { id_peserta, type, jawaban, id_simulasi, datadrag, id_jadwal } = this.state
    // console.log(jawaban)
    // const baseURLGet =
    //   process.env.REACT_APP_BACKEND_URL_VERSION +
    //   `jawaban/jawaban-peserta?id_peserta=` +
    //   id_peserta +
    //   `&id_simulasi=` +
    //   id_simulasi +
    //   '&id_jadwal=' +
    //   id_jadwal

    // fetch(baseURLGet, {
    //   method: "GET",
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("access_token_2"),
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then(response => response.json())
    //   .then(responseData => {
    //     console.log(responseData)
    //     if (responseData.meta.code === 200) {
    //       const myHeaders = new Headers();
    //       myHeaders.append("Content-Type", "application/json");
    //       myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

    //       const raw = JSON.stringify({
    //         "id_peserta": id_peserta,
    //         "jawaban": jawaban,
    //         "jawaban_mengurutkan": datadrag,
    //         "id_simulasi": id_simulasi,
    //         "id_jadwal": id_jadwal
    //       });


    //       const baseURLGet =
    //         process.env.REACT_APP_BACKEND_URL_VERSION +
    //         "jawaban/jawaban-peserta"

    //       const requestOptions = {
    //         method: "PUT",
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: "follow"
    //       };

    //       fetch(baseURLGet, requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //           console.log(result)
    //           this.handleSaveLog(result.data[0].id_jawaban)
    //         })
    //         .catch(error => {
    //           console.log("error", error)
    //         })
    //     } else {
    //       const baseURL =
    //         process.env.REACT_APP_BACKEND_URL_VERSION +
    //         "jawaban/jawaban-peserta"

    //       var formdata = new FormData()
    //       formdata.append("id_peserta", id_peserta)
    //       formdata.append("id_simulasi", id_simulasi)
    //       formdata.append("jawaban", jawaban)
    //       formdata.append("type", type)
    //       formdata.append("id_jadwal", id_jadwal)

    //       var myHeaders = new Headers()
    //       myHeaders.append(
    //         "Authorization",
    //         "Bearer " + localStorage.getItem("access_token_2")
    //       )

    //       var requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: formdata,
    //         redirect: "follow",
    //       }

    //       fetch(baseURL, requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //           console.log(result)
    //           this.handleSaveLog(result.data[0].id_jawaban)
    //         })
    //         .catch(error => {
    //           console.log("error", error)
    //         })
    // }
    // })
    // .catch(error => {
    //   console.log("error", error)
    // })
  }

  handleSaveLog = id_jawaban => {
    console.log("log send....")
    const { id_peserta, type, jawaban, id_simulasi, timeLeft, datadrag, id_jadwal, id_jadwal_asesi } = this.state
    // const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/log"
    // var formdata = new FormData()
    // formdata.append("id_peserta", id_peserta)
    // formdata.append("id_jadwal", id_jadwal)
    // formdata.append("id_simulasi", id_simulasi)
    // formdata.append("value", jawaban)
    // formdata.append("value_urut", datadrag)
    // formdata.append("tipe", type)
    // formdata.append("id_jawaban_peserta", id_jawaban)
    // formdata.append("timer", timeLeft)

    // var myHeaders = new Headers()
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer " + localStorage.getItem("access_token_2")
    // )

    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // }

    // fetch(baseURL, requestOptions)
    //   .then(response => response.text())
    //   .then(result => {
    //     console.log(result)
    //   })
    //   .catch(error => {
    //     console.log("error", error)
    //     Swal.close() // Close loading state
    //     Swal.fire("Error", "Jawaban gagal disimpan", "error")
    //   })

    CaptureRecords(id_jadwal, id_jadwal_asesi)
  }

  handleUpdateStatus() {
    const { id_simulasi, id_jadwal_asesi } = this.state
    // const params = new URLSearchParams()
    // params.append("status_simulasi", "selesai")
    // params.append("id_jadwal_asesi", id_jadwal_asesi)
    // params.append("id_simulasi", id_simulasi)

    // var myHeaders = new Headers()
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer " + localStorage.getItem("access_token_2")
    // )

    // var requestOptions = {
    //   method: "PUT",
    //   headers: myHeaders,
    //   redirect: "follow",
    // }

    // const url = `${process.env.REACT_APP_BACKEND_URL_VERSION
    //   }Status-simulasi?${params.toString()}`

    // fetch(url, requestOptions)
    //   .then(response => response.text())
    //   .then(result => {
    //     window.location.href = `http://utt-bpkp.alfahuma.com/dashboard`
    //   })
    //   .catch(error => console.log(error))

  }
  handledelete() {
    const { id_peserta, id_simulasi } = this.state
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

    // var requestOptions = {
    //   method: 'DELETE',
    //   headers: myHeaders,
    //   redirect: 'follow'
    // };

    // fetch(process.env.REACT_APP_BACKEND_URL_VERSION +
    //   "jawaban/waktu?id_peserta=" +
    //   id_peserta +
    //   "&id_simulasi=" +
    //   id_simulasi, requestOptions)
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));
  }
  stopTimer() {
    clearInterval(this.interval);
    this.interval = null;
  }

  render() {

    const { timeLeft } = this.state
    const hours = Math.floor(timeLeft / 3600)
    const minutes = Math.floor((timeLeft % 3600) / 60)
    const seconds = timeLeft % 60
    if (minutes === 10 && seconds === 0 && hours === 0) {
      toast.info('Only 10 minutes remaining!', { position: toast.POSITION.TOP_RIGHT });
    }
    return (
      <>
        <ToastContainer />
        <i className="bx bxs-timer me-2" />
        {hours < 10 ? "0" : ""}
        {hours}:{minutes < 10 ? "0" : ""}
        {minutes}:{seconds < 10 ? "0" : ""}
        {seconds}
      </>
    )
  }
}


export default CountdownTimer
