import React, { useState, useEffect } from "react"
import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Nav,
  NavItem,
  NavLink,
  CardText,
  TabContent,
  TabPane,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap"
import SplitPane, { Pane } from "split-pane-react"

// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import classnames from "classnames"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"
import Swal from "sweetalert2"
import ConverterSoal from "components/ConverterSoal/ConverterSoal"
import SessionTimer from "components/CountdownTimer/SessionTimer"
import CheckStatus from "components/CheckStatus/CheckStatus"
import Calculator from "components/Calculator/calculator"
import SoalText from "components/RefrensiJawaban/SoalText"
import Base64UploadAdapter from "components/Editor/Base64UploadAdapter"

const SoalTextMemo = React.memo(SoalText)

const FormEditors = () => {
  //meta title
  document.title = "Soal Simulasi"
  const { id } = useParams()
  const { id2 } = useParams()
  const [activeTab1, setactiveTab1] = useState("5")
  const [activeCard, setactiveCard] = useState("7")
  const [activeCardEditor, setactiveCardEditor] = useState("5")
  const [isHidden, setIsHidden] = useState(false)
  const [isHiddenEditor, setIsHiddenEditor] = useState(false)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const userData = JSON.parse(localStorage.getItem("authUser"))
  const [editorData, setEditorData] = useState("")
  const [modal, setModal] = useState(false)
  const [random, setRandom] = useState(false)
  const [dataRandom, setDataRandom] = useState()
  const [defaultHeight, setDefaultHight] = useState("100vh")
  const [lastUpdate, setLastUpdate] = useState()
  const [expandStatus, setExpandStatus] = useState(false)
  const [autoSave, setAutoSave] = useState(false)
  const [sizes, setSizes] = useState(["100%", 0])
  const navigate = useNavigate()
  const [jawabanLoading, setJawabanLoading] = useState(true)

  const layoutCSS = {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  }

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  function expand() {
    if (expandStatus == false) {
      setSizes(["100%", 0])
      setExpandStatus(true)
      setDefaultHight("100vh")
    } else {
      setSizes(["100%", "100%"])
      setExpandStatus(false)
      setDefaultHight("400px")
    }
  }

  function expandEditor() {
    if (expandStatus == false) {
      setSizes([0, "100%"])
      setExpandStatus(true)
      setDefaultHight("100vh")
    } else {
      setSizes(["100%", "100%"])
      setExpandStatus(false)
      setDefaultHight("400px")
    }
  }

  const openKalkulator = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  useEffect(() => {
    CheckStatus(id2, id)
    setLoading(true)

    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "Soal-simulasi?id_simulasi=" +
      id +
      "&id_asesi=" +
      id2
    Swal.fire({
      title: "Loading",
      text: "Please wait while we fetch the data...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          console.log(responseData)
          if (responseData.data[0].data[0].msn_mks_nama_kategori === "Tipe 5") {
            setRandom(true)
            const urlRand =
              process.env.REACT_APP_BACKEND_URL_VERSION +
              "soal-random?id_peserta=" +
              userData.id_peserta +
              "&id_simulasi=" +
              id
            var myHeaders = new Headers()
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("access_token_2")
            )

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            }

            fetch(urlRand, requestOptions)
              .then(response => response.json())
              .then(result => {
                if (result.meta.code === 200) {
                  setDataRandom(result.data[0])
                }
              })
              .catch(error => console.log("error", error))
          }
          setData(responseData.data[0].data)
          console.log(responseData)
          setLoading(false)
          Swal.close()
        } else {
          setLoading(false)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseData.meta.message,
          }).then(() => {
            window.location.href = `https://mac.bpkp.go.id/assessment_utt/dashboard`
          })
        }
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again later.",
        }).then(() => {
          window.location.href = `https://mac.bpkp.go.id/assessment_utt/dashboard`
        })
      })
  }, [])

  const getJawaban = () => {
    if (data && data[0]) {
      setJawabanLoading(true)
      const baseURLGet =
        process.env.REACT_APP_BACKEND_URL_VERSION +
        `jawaban/jawaban-peserta?id_peserta=` +
        userData.id_peserta +
        `&id_simulasi=` +
        id +
        "&id_jadwal=" +
        data[0].mjs_id_mjadwal

      fetch(baseURLGet, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token_2"),
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(responseData => {
          console.log(responseData)
          if (responseData.meta.code === 200) {
            if (responseData.data[0].data.value) {
              setEditorData(responseData.data[0].data.value)
              setLastUpdate(responseData.data[0].data.updated_at)
            }
          }
          setJawabanLoading(false)
        })
        .catch(error => {
          console.log(error)
          setJawabanLoading(false)
        })
    }
  }

  useEffect(() => {
    getJawaban()
  }, [data])

  useEffect(() => {
    const handleWheelEvent = event => {
      if (event.ctrlKey) {
        event.preventDefault()
      }
    }

    window.addEventListener("wheel", handleWheelEvent, { passive: false })

    return () => {
      window.removeEventListener("wheel", handleWheelEvent)
    }
  }, [])

  const handleChange = (event, editor) => {
    const data = editor.getData()
    setEditorData(data)
    setAutoSave(true)
  }

  const handleSubmit = () => {
    Swal.fire({
      title: "KONFIRMASI",
      text: "Anda yakin untuk menyelesaikan soal ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }) // Display loading state
        // console.log("test datav", data[0].data.mfm_id_simulasi)

        localStorage.removeItem("access_session")
        const baseURLGet =
          process.env.REACT_APP_BACKEND_URL_VERSION +
          `jawaban/jawaban-peserta?id_peserta=` +
          userData.id_peserta +
          `&id_simulasi=` +
          id +
          "&id_jadwal=" +
          data[0].mjs_id_mjadwal

        fetch(baseURLGet, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token_2"),
            "Content-Type": "application/json",
          },
        })
          .then(response => response.json())
          .then(responseData => {
            console.log("Konfirmasi", responseData)
            if (responseData.meta.code === 200) {
              const myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

              const raw = JSON.stringify({
                "id_peserta": userData.id_peserta,
                "jawaban": editorData,
                "id_simulasi": id,
                "id_jadwal": data[0].mjs_id_mjadwal
              });

              const requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
              };

              const baseURLGet =
                process.env.REACT_APP_BACKEND_URL_VERSION +
                "jawaban/jawaban-peserta"

              fetch(baseURLGet, requestOptions)
                .then(response => response.text())
                .then(result => {
                  handledelete()
                  Swal.close() // Close loading state
                  Swal.fire(
                    "Success",
                    "Jawaban telah diupdate",
                    "success"
                  ).then(() => {
                    var myHeaders = new Headers()
                    myHeaders.append(
                      "Authorization",
                      "Bearer " + localStorage.getItem("access_token_2")
                    )

                    var requestOptions = {
                      method: "PUT",
                      headers: myHeaders,
                      redirect: "follow",
                    }

                    fetch(
                      process.env.REACT_APP_BACKEND_URL_VERSION +
                      `Status-simulasi?status_simulasi=selesai&id_jadwal_asesi=${id2}&id_simulasi=${id}`,
                      requestOptions
                    )
                      .then(response => response.json())
                      .then(result => {
                        if (result.meta.code === 200) {
                          window.location.href = `https://mac.bpkp.go.id/assessment_utt/dashboard`
                        }
                      })
                      .catch(error => console.log(""))
                  })

                  localStorage.removeItem("access_session")
                })
                .catch(error => {
                  console.log("error", error)
                  Swal.close() // Close loading state
                  Swal.fire("Error", "Jawaban gagal diupdate", "error")
                })
            } else {
              const baseURL =
                process.env.REACT_APP_BACKEND_URL_VERSION +
                "jawaban/jawaban-peserta"

              var formdata = new FormData()
              formdata.append("id_peserta", userData.id_peserta)
              formdata.append("id_simulasi", id)
              formdata.append("jawaban", editorData)
              formdata.append("type", data[0].msn_mks_nama_kategori)
              formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

              var myHeaders = new Headers()
              myHeaders.append(
                "Authorization",
                "Bearer " + localStorage.getItem("access_token_2")
              )

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
              }

              fetch(baseURL, requestOptions)
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  Swal.close() // Close loading state
                  Swal.fire(
                    "Success",
                    "Jawaban telah disimpan",
                    "success"
                  ).then(() => {
                    var myHeaders = new Headers()
                    myHeaders.append(
                      "Authorization",
                      "Bearer " + localStorage.getItem("access_token_2")
                    )

                    var requestOptions = {
                      method: "PUT",
                      headers: myHeaders,
                      redirect: "follow",
                    }

                    fetch(
                      process.env.REACT_APP_BACKEND_URL_VERSION +
                      `Status-simulasi?status_simulasi=selesai&id_jadwal_asesi=${id2}&id_simulasi=${id}`,
                      requestOptions
                    )
                      .then(response => response.json())
                      .then(result => {
                        if (result.meta.code === 200) {
                          window.location.href = `https://mac.bpkp.go.id/assessment_utt/dashboard`
                        }
                      })
                      .catch(error => console.log(""))

                  })
                })
                .catch(error => {
                  console.log("error", error)
                  Swal.close() // Close loading state
                  Swal.fire("Error", "Jawaban gagal disimpan", "error")
                })
            }
          })
          .catch(error => {
            const baseURL =
              process.env.REACT_APP_BACKEND_URL_VERSION +
              "jawaban/jawaban-peserta"

            var formdata = new FormData()
            formdata.append("id_peserta", userData.id_peserta)
            formdata.append("id_simulasi", id)
            formdata.append("jawaban", editorData)
            formdata.append("type", data[0].msn_mks_nama_kategori)
            formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

            var myHeaders = new Headers()
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("access_token_2")
            )

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow",
            }

            fetch(baseURL, requestOptions)
              .then(response => response.text())
              .then(result => {
                console.log(result)
                Swal.close() // Close loading state
                Swal.fire(
                  "Success",
                  "Jawaban telah disimpan",
                  "success"
                ).then(() => {
                  var myHeaders = new Headers()
                  myHeaders.append(
                    "Authorization",
                    "Bearer " + localStorage.getItem("access_token_2")
                  )

                  var requestOptions = {
                    method: "PUT",
                    headers: myHeaders,
                    redirect: "follow",
                  }

                  fetch(
                    process.env.REACT_APP_BACKEND_URL_VERSION +
                    `Status-simulasi?status_simulasi=selesai&id_jadwal_asesi=${id2}&id_simulasi=${id}`,
                    requestOptions
                  )
                    .then(response => response.json())
                    .then(result => {
                      if (result.meta.code === 200) {
                        window.location.href = `https://mac.bpkp.go.id/assessment_utt/dashboard`
                      }
                    })
                    .catch(error => console.log(""))

                })
              })
              .catch(error => {
                console.log("error", error)
                Swal.close() // Close loading state
                Swal.fire("Error", "Jawaban gagal disimpan", "error")
              })
          })
      }
    })
  }

  const handleSave = () => {
    Swal.fire({
      title: "KONFIRMASI",
      text: "Anda yakin untuk menyimpan jawaban ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }) // Display loading state

        const baseURLGet =
          process.env.REACT_APP_BACKEND_URL_VERSION +
          `jawaban/jawaban-peserta?id_peserta=` +
          userData.id_peserta +
          `& id_simulasi=` +
          id +
          "&id_jadwal=" +
          data[0].mjs_id_mjadwal

        fetch(baseURLGet, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token_2"),
            "Content-Type": "application/json",
          },
        })
          .then(response => response.json())
          .then(responseData => {
            if (responseData.meta.code === 200) {
              setLastUpdate(responseData.data[0].updated_at)
              const myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

              const raw = JSON.stringify({
                "id_peserta": userData.id_peserta,
                "jawaban": editorData,
                "id_simulasi": id,
                "id_jadwal": data[0].mjs_id_mjadwal
              });

              const baseURLGet =
                process.env.REACT_APP_BACKEND_URL_VERSION +
                "jawaban/jawaban-peserta"

              const requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
              };

              fetch(baseURLGet, requestOptions)
                .then(response => response.text())
                .then(result => {
                  Swal.close() // Close loading state
                  Swal.fire(
                    "Success",
                    "Jawaban telah diupdate",
                    "success"
                  ).then(() => {
                    getJawaban()
                  })
                })
                .catch(error => {
                  console.log("error", error)
                  Swal.close() // Close loading state
                  Swal.fire("Error", "Jawaban gagal diupdate", "error")
                })
            } else {
              const baseURL =
                process.env.REACT_APP_BACKEND_URL_VERSION +
                "jawaban/jawaban-peserta"

              var formdata = new FormData()
              formdata.append("id_peserta", userData.id_peserta)
              formdata.append("id_simulasi", id)
              formdata.append("jawaban", editorData)
              formdata.append("type", data[0].msn_mks_nama_kategori)
              formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

              var myHeaders = new Headers()
              myHeaders.append(
                "Authorization",
                "Bearer " + localStorage.getItem("access_token_2")
              )

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
              }

              fetch(baseURL, requestOptions)
                .then(response => response.json())
                .then(responseData => {
                  console.log(responseData)
                  if (responseData.meta.code === 200) {
                    setLastUpdate(responseData.data[0].updated_at)
                  }
                  Swal.close() // Close loading state
                  Swal.fire(
                    "Success",
                    "Jawaban telah disimpan",
                    "success"
                  ).then(() => {
                    getJawaban()
                  })
                })
                .catch(error => {
                  console.log("error", error)
                  Swal.close() // Close loading state
                  Swal.fire("Error", "Jawaban gagal disimpan", "error")
                })
            }
          })
          .catch(error => {
            const baseURL =
              process.env.REACT_APP_BACKEND_URL_VERSION +
              "jawaban/jawaban-peserta"

            var formdata = new FormData()
            formdata.append("id_peserta", userData.id_peserta)
            formdata.append("id_simulasi", id)
            formdata.append("jawaban", editorData)
            formdata.append("type", data[0].msn_mks_nama_kategori)
            formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

            var myHeaders = new Headers()
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("access_token_2")
            )

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow",
            }

            fetch(baseURL, requestOptions)
              .then(response => response.json())
              .then(responseData => {
                console.log(responseData)
                if (responseData.meta.code === 200) {
                  setLastUpdate(responseData.data[0].updated_at)
                }
                Swal.close() // Close loading state
                Swal.fire(
                  "Success",
                  "Jawaban telah disimpan",
                  "success"
                ).then(() => {
                  getJawaban()
                })
              })
              .catch(error => {
                console.log("error", error)
                Swal.close() // Close loading state
                Swal.fire("Error", "Jawaban gagal disimpan", "error")
              })
          })
      }
    })
  }

  const handledelete = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/waktu?id_peserta=" +
      userData.id_peserta +
      "&id_simulasi=" +
      id,
      requestOptions
    )
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log("error", error))
  }

  //Auto Save
  useEffect(() => {
    const fetchData = () => {
      if (autoSave === true) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

        const raw = JSON.stringify({
          "id_peserta": userData.id_peserta,
          "jawaban": editorData,
          "id_simulasi": id,
          "id_jadwal": data[0].mjs_id_mjadwal
        });

        const baseURLGet =
          process.env.REACT_APP_BACKEND_URL_VERSION +
          "jawaban/jawaban-peserta"

        const requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        fetch(baseURLGet, requestOptions)
          .then(response => response.json())
          .then(result => {
            // Handle the result if needed
          })
          .catch(error => {
            // Handle errors if any
          })
      }
    }

    // Initial fetch
    fetchData()

    // Schedule subsequent fetch every 30 seconds
    const interval = setInterval(fetchData, 30000)

    // Clear interval on component unmount
    return () => clearInterval(interval)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {data[0] ? (
            <Breadcrumbs
              title="Simulasi"
              breadcrumbItem={data[0].mfm_ms_nama_simulasi}
            />
          ) : (
            <Breadcrumbs title="Simulasi" breadcrumbItem="Loading ...." />
          )}
          <Row>
            <Card>
              <CardBody>
                <div className="bg-primary bg-soft p-3 rounded">
                  <h3 className="font-size-25 text-primary mb-0">
                    {data && data[0] && data[0].mjs_durasi && (
                      <SessionTimer
                        data={data[0]}
                        editorData={editorData}
                        lastUpdate={lastUpdate}
                        idJadwal={data[0].mjs_id_mjadwal}
                      />
                    )}
                  </h3>
                </div>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <div style={{ height: 700 }}>
              <div style={{ ...layoutCSS, background: "white" }}>
                <Col>
                  <Card className="mx-1">
                    <CardHeader className="bg-light">
                      <Row>
                        <Col className="col-8">
                          <Nav pills className="navtab-bg nav-justified">
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "5",
                                })}
                                onClick={() => {
                                  toggle1("5")
                                }}
                              >
                                Instruksi
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "6",
                                })}
                                onClick={() => {
                                  toggle1("6")
                                }}
                              >
                                Soal
                              </NavLink>
                            </NavItem>
                            {data &&
                              data[0] &&
                              data[0].mjs_id_simulasi_referensi && (
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab1 === "7",
                                    })}
                                    onClick={() => {
                                      toggle1("7")
                                    }}
                                  >
                                    Referensi
                                  </NavLink>
                                </NavItem>
                              )}
                          </Nav>
                        </Col>
                        <Col className="d-flex aling-item-start justify-content-end">
                          <button
                            type="button"
                            onClick={() => {
                              expand()
                            }}
                            className="btn noti-icon "
                          >
                            <i className="bx bx-fullscreen" />
                          </button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <TabContent
                        activeTab={activeTab1}
                        className="p-3 text-muted"
                      >
                        <TabPane tabId="5">
                          <Row>
                            <Col sm="12">
                              <div
                                style={{
                                  height: defaultHeight,
                                  overflow: "auto",
                                }}
                              >
                                {data &&
                                  data.map(
                                    (item, index) =>
                                      item.mfm_type === "Petunjuk" &&
                                      (item.mfm_mf_dir ||
                                        item.mfm_file_petunjuk_dir) && (
                                        <ConverterSoal
                                          tipeData="Dokumen"
                                          linkData={
                                            item.mfm_mf_dir
                                              ? item.mfm_mf_dir
                                              : item.mfm_file_petunjuk_dir
                                          }
                                          key={index}
                                        />
                                      )
                                  )}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="6">
                          <Row>
                            <Col sm="12">
                              <div
                                style={{
                                  height: defaultHeight,
                                  overflow: "auto",
                                }}
                              >
                                {dataRandom && dataRandom[0] ? (
                                  <>
                                    <ConverterSoal
                                      tipeData={data[0].mfm_type}
                                      linkData={
                                        "webfile/" + dataRandom[0].jr_filenama
                                      }
                                    />
                                  </>
                                ) : (
                                  data &&
                                  data.map(
                                    (item, index) =>
                                      item.mfm_type !== "Petunjuk" && (
                                        <div key={index}>
                                          <ConverterSoal
                                            tipeData={item.mfm_type}
                                            linkData={item.mfm_mf_dir}
                                          />
                                        </div>
                                      )
                                  )
                                )}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        {data &&
                          data[0] &&
                          data[0].mjs_id_simulasi_referensi && (
                            <TabPane tabId="7">
                              <SoalTextMemo
                                id_referensi={data[0].mjs_id_simulasi_referensi}
                                id_jadwal={data[0].mjs_id_mjadwal}
                                id_peserta={userData.id_peserta}
                              />
                            </TabPane>
                          )}
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </div>
            </div>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-soft-primary ms-2 bd-highlight"
                    onClick={handleSave}
                  >
                    Simpan Jawaban
                  </button>
                  <button
                    type="submit"
                    className="btn btn-soft-success ms-2 bd-highlight"
                    onClick={handleSubmit}
                  >
                    Selesai
                  </button>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>

        <Modal isOpen={modal} toggle={openKalkulator}>
          <Calculator draggable="true" />
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default FormEditors
