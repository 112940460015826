import React from "react"

import { Card, CardBody, CardHeader, Table } from "reactstrap"


const DetailPeserta = (props) => {
  const data = props.dataJadwal;
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <CardBody>
          <div>
            {data.length > 0 ? (
              data[0].jadwal.map(item => (
                <React.Fragment key={item.id_mjadwal}>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        <tr >
                          <th>Nomer Registrasi</th>
                          <th>:</th>
                          <td>{item.mj_noreg} </td>
                        </tr>
                        <tr>
                          <th>Password</th>
                          <th>:</th>
                          <td>{item.mj_pass}</td>
                        </tr>
                        <tr>
                          <th>Tanggal Mulai</th>
                          <th>:</th>
                          <td>{item.mj_tglmulai}</td>
                        </tr>
                        <tr>
                          <th>Tanggal Selesai</th>
                          <th>:</th>
                          <td>{item.mj_tglselesai}</td>
                        </tr>
                        <tr>
                          <th>Target Jabatan</th>
                          <th>:</th>
                          <td>{item.target_jabatan.target_nama}</td>
                        </tr>
                        <tr>
                          <th>Instansi</th>
                          <th>:</th>
                          <td>{item.instansi.nama_instansi}</td>
                        </tr>
                        <tr>
                          <th>Lokasi</th>
                          <th>:</th>
                          <td>{item.mj_lokasi}</td>
                        </tr>
                        <tr>
                          <th>PIC</th>
                          <th>:</th>
                          <td>{item.mj_pic_name}</td>
                        </tr>
                        <tr>
                          <th>Tipe Jadwal</th>
                          <th>:</th>
                          <td>{item.mj_type}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div>No Data...</div>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default DetailPeserta
