import React, { useState, useEffect } from "react"
import { Card, Col, Row, CardHeader, Button } from "reactstrap"
import ConverterSoal from "components/ConverterSoal/ConverterSoal"

function SoalText(props) {
  const [valueReference, setValueReference] = useState()
  const [item, setItem] = useState({}) // Assuming item will be set later from fetched data
  const [expandedItems, setExpandedItems] = useState({})

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
        "jawaban/jawaban-refrensi?id_jadwal=" +
        props.id_jadwal +
        "&id_referensi=" +
        props.id_referensi +
        "&id_peserta=" +
        props.id_peserta,
      requestOptions
    )
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          const data = responseData.data[0].data
          const dataFile = responseData.data[0].data_file

          if (data && data.value && data.tipe != "Tipe 7") {
            setValueReference(data.value)
          }

          if (data.tipe == "Tipe 7") {
          }

          if (Array.isArray(dataFile) && dataFile.length > 0) {
            const files = dataFile.map(file => ({
              id: file.id,
              filePath: file.juf_file_dir,
              fileName: file.juf_file_name,
            }))
            setItem(files)
          }
        }
      })
      .catch(error => console.log("error", error))
  }, [props])

  function copyReference() {
    try {
      // Attempt to parse valueReference as JSON
      const parsedData = JSON.parse(valueReference)

      // Format the content as plain text
      const plainTextContent = Object.entries(parsedData)
        .map(([question, answer]) => {
          // Strip HTML tags from each answer
          const tempDiv = document.createElement("div")
          tempDiv.innerHTML = answer
          const plainTextAnswer = tempDiv.textContent || tempDiv.innerText

          return `${question}\n${plainTextAnswer}\n`
        })
        .join("\n")

      // Copy the formatted plain text content to the clipboard
      navigator.clipboard.writeText(plainTextContent)
    } catch (e) {
      // Fallback to copying plain text if JSON parsing fails
      const tempElement = document.createElement("div")
      tempElement.innerHTML = valueReference
      const plainText = tempElement.textContent || tempElement.innerText
      navigator.clipboard.writeText(plainText)
    }
  }

  function renderValueReference() {
    try {
      // Attempt to parse valueReference as JSON
      const parsedData = JSON.parse(valueReference)
      return (
        <div>
          {Object.entries(parsedData).map(([question, answer], index) => {
            // Create a temporary div to strip HTML tags from the answer
            const tempDiv = document.createElement("div")
            tempDiv.innerHTML = answer
            const plainTextAnswer = tempDiv.textContent || tempDiv.innerText

            return (
              <div key={index} style={{ marginBottom: "10px" }}>
                <strong>{question}</strong>
                <p>{plainTextAnswer}</p>
              </div>
            )
          })}
        </div>
      )
    } catch (e) {
      // Fallback if valueReference is not valid JSON
      return <div dangerouslySetInnerHTML={{ __html: valueReference }} />
    }
  }

  const toggleExpand = index => {
    setExpandedItems(prev => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  return (
    <Card>
      <CardHeader style={{ maxHeight: "600px", overflow: "auto" }}>
        <Row>
          <Col sm="11">
            {valueReference ? (
              renderValueReference()
            ) : (
              <p>No reference available.</p>
            )}
          </Col>
          <Col sm="1">
            <Button
              className="btn btn-soft-primary"
              onClick={copyReference}
              disabled={!valueReference}
            >
              <span className="mdi mdi-clipboard-file-outline"></span>
            </Button>
          </Col>
        </Row>

        {/* Validation for the file link */}
        {item.length > 0 ? (
          <Row>
            <Col sm="12">
              {item.length > 0 ? (
                item.map((file, index) => (
                  <div key={index} style={{ marginBottom: "16px" }}>
                    {/* Judul yang dapat di-expand */}
                    <h5
                      onClick={() => toggleExpand(index)}
                      className={`cursor-pointer ${
                        expandedItems[index] ? "bg-primary text-white" : ""
                      }`}
                      style={{
                        padding: "8px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      {`Referensi File ${index + 1}`}
                    </h5>

                    {/* Konten yang di-expand */}
                    {expandedItems[index] && (
                      <ConverterSoal
                        tipeData="Dokumen Referensi"
                        linkData={file.filePath}
                      />
                    )}
                  </div>
                ))
              ) : (
                <p>No document available for conversion.</p>
              )}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm="12">
              <p>No document available for conversion.</p>
            </Col>
          </Row>
        )}
      </CardHeader>
    </Card>
  )
}

export default SoalText
