import React, { Fragment, useEffect, useState } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Modal,
  ModalHeader,
  Input,
  Button,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getTasks as onGetTasks } from "../../../store/tasks/actions"
import DetailJadwal from "./DetailJadwal"
import InProse from "./InProses"
import Swal from "sweetalert2"

//redux
import { useSelector, useDispatch } from "react-redux"

const TasksList = () => {
  //meta title
  document.title = "List Soal"

  const navigate = useNavigate()
  const handleViewDetails = (...ids) => {
    // Navigate to page two with the id as a prop
    // navigate(`/simulasi/${ids.id}`)
    if (ids[1] === "Tipe 1") {
      navigate(`/SoalInbasket/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 2") {
      navigate(`/SoalCI/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 3") {
      navigate(`/SoalCA/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 4") {
      navigate(`/SoalPresentasi/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 5") {
      navigate(`/SoalCA/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 6") {
      navigate(`/SoalMengurutkan/${ids[0]}/${ids[2]}`)
    } else if (ids[1] === "Tipe 7") {
      navigate(`/UploadFile/${ids[0]}/${ids[2]}`)
    }
  }

  const { id } = useParams()
  const [data, setData] = useState([])
  const [dataBrief, setDataBrief] = useState()
  const [loading, setLoading] = useState(true)
  //date now
  const now = new Date()

  // Membuat currentDate dengan format YYYY-MM-DD (mengganti toLocaleDateString)
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, "0") // Bulan dimulai dari 0
  const day = String(now.getDate()).padStart(2, "0")
  const currentDate = `${year}-${month}-${day}`

  // Membuat currentTime dengan format HH:MM:SS (mengganti toLocaleTimeString)
  const hours = String(now.getHours()).padStart(2, "0")
  const minutes = String(now.getMinutes()).padStart(2, "0")
  const seconds = String(now.getSeconds()).padStart(2, "0")
  const currentTime = `${hours}:${minutes}:${seconds}`
  
  const [showModal, setShowModal] = useState(false)
  const [kodelink, setKode] = useState()
  const [linkZoom, setLink] = useState()
  const [idJadwal, setIdAsesi] = useState()
  const [idSimulasi, setIdSimulasi] = useState()

  useEffect(() => {
    setLoading(true)
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION + "materi?id_jadwal_asesi=" + id
    Swal.fire({
      title: "Loading",
      text: "Please wait while we fetch the data...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          setData(responseData.data)
          setDataBrief(responseData.data[0].jadwal[0].mj_file_brief)
          setLoading(false)
          Swal.close()
        } else {
          setLoading(false)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseData.meta.message,
          }).then(() => {
            window.history.back()
          })
        }
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again later.",
        }).then(() => {
          window.history.back()
        })
      })
  }, [])

  const handleChangePage = (idJadwal, idSimulasi, kategoriSimulasi) => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
        `Status-simulasi?status_simulasi=proses&id_jadwal_asesi=${idJadwal}&id_simulasi=${idSimulasi}`,
      requestOptions
    )
      .then(response => response.text())
      .then(result => console.log(""))
      .catch(error => console.log(""))

    handleViewDetails(idSimulasi, kategoriSimulasi, idJadwal)
  }

  const handleOpenModal = (asesi, simulasi, kode, link) => {
    setShowModal(true)
    setKode(kode)
    setLink(link)
    setIdAsesi(asesi)
    setIdSimulasi(simulasi)
  }
  const handleLinkClick = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
        `Status-simulasi?status_simulasi=proses&id_jadwal_asesi=${idJadwal}&id_simulasi=${idSimulasi}`,
      requestOptions
    )
      .then(response => response.text())
      .then(result => console.log(""))
      .catch(error => console.log(""))

    event.preventDefault()
    window.open(linkZoom, "_blank")
  }

  const handleDownloadFile = async () => {
    const url = process.env.REACT_APP_FILE_DIGIMAC_URL
    if (dataBrief) {
      const fileUrl = url + dataBrief
      const fileName = dataBrief.split("/").pop() // Extract the file name from the URL

      try {
        const response = await fetch(fileUrl)
        if (!response.ok) {
          throw new Error("Failed to fetch the file.")
        }

        const blob = await response.blob()
        const blobUrl = URL.createObjectURL(blob)

        const link = document.createElement("a")
        link.href = blobUrl
        link.download = fileName
        link.click()
      } catch (error) {
        Swal.fire({
          title: "ERROR",
          text: "Failed to download the file.",
          icon: "error",
          confirmButtonText: "OK",
        })
      }
    } else {
      Swal.fire({
        title: "WARNING",
        text: "File not found.",
        icon: "warning",
        confirmButtonText: "OK",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Soal Simulai" breadcrumbItem="List Simulasi" />
          <Row>
            <Col xl={6} className="d-flex justify-content-start">
              <button
                className="btn btn-primary mb-4 rounded-3"
                onClick={() => window.history.back()}
              >
                <i className="fa fa-arrow-left"></i> Kembali
              </button>
            </Col>
            <Col xl={6} className="d-flex justify-content-end">
              <button
                onClick={() => handleDownloadFile()}
                className="btn btn-success mb-4 rounded-3"
              >
                <i className="fa fa-arrow-down"></i> Download File Panduan
              </button>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              {loading ? (
                <div>Loading...</div>
              ) : (
                <React.Fragment>
                  <DetailJadwal dataJadwal={data} />
                </React.Fragment>
              )}
            </Col>
            <Col xl={8}>
              <Row>
                {data.length > 0 ? (
                  data[0].simulasi.map(item => (
                    <Col xl={6} key={item.mjs_id_simulasi}>
                      {currentDate >= item.mjs_tanggal_pelaksanaan_mulai &&
                      currentDate <= item.mjs_tanggal_pelaksanaan_selesai ? (
                        <Card>
                          <CardBody>
                            {item.status === "selesai" ? (
                              <CardTitle>
                                <div className="bg-success bg-soft p-3 rounded">
                                  <h5 className="font-size-14 text-success mb-0">
                                    {item.mjs_simulasi}(
                                    {item.msn_mks_nama_kategori})
                                    <span className="float-end text-success">
                                      {" "}
                                      <i className="fa fa-check-circle me-2" />
                                      {item.status}
                                    </span>
                                  </h5>
                                </div>
                              </CardTitle>
                            ) : item.status === "proses" ? (
                              <div>
                                <CardTitle>
                                  <div className="bg-primary bg-soft p-3 rounded">
                                    <h5 className="font-size-14 text-primary mb-0">
                                      {item.mjs_simulasi}(
                                      {item.msn_mks_nama_kategori})
                                      <span className="float-end text-primary">
                                        {" "}
                                        <i className="bx bxs-timer me-2" />
                                        {item.status}
                                      </span>
                                    </h5>
                                  </div>
                                </CardTitle>
                                <InProse
                                  id_simulasi={item.mjs_id_simulasi}
                                  id_asesi={item.id_jadwal_asesi}
                                />
                              </div>
                            ) : (
                              <CardTitle>
                                <div className="bg-danger bg-soft p-3 rounded">
                                  <h5 className="font-size-14 text-danger mb-0">
                                    {item.mjs_simulasi}(
                                    {item.msn_mks_nama_kategori})
                                    <span className="float-end text-danger">
                                      {" "}
                                      <i className="bx bxs-timer me-2" />
                                      {item.status}
                                    </span>
                                  </h5>
                                </div>
                              </CardTitle>
                            )}
                            <Row>
                              <Col xl={12}>
                                <div className="table-responsive">
                                  <table className="table table-nowrap align-middle mb-0">
                                    <tbody>
                                      <tr>
                                        <th>Tanggal</th>
                                        <th>:</th>
                                        <td>
                                          {item.mjs_tanggal_pelaksanaan_mulai} -{" "}
                                          {item.mjs_tanggal_pelaksanaan_selesai}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Waktu</th>
                                        <th>:</th>
                                        <td>
                                          {item.mjs_jam_pelaksanaan_mulai} -{" "}
                                          {item.mjs_jam_pelaksanaan_selesai} WIB
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col xl={12}>
                                <div className="text-end">
                                  {item.status === "Belum Dikerjakan" &&
                                  currentTime >=
                                    item.mjs_jam_pelaksanaan_mulai &&
                                  currentTime <=
                                    item.mjs_jam_pelaksanaan_selesai ? (
                                    <div>
                                      {item.msn_mks_nama_kategori !=
                                        "Tipe 8" && (
                                        <Button
                                          className="btn btn-soft-primary m-2"
                                          onClick={() =>
                                            handleChangePage(
                                              item.id_jadwal_asesi,
                                              item.mjs_id_simulasi,
                                              item.msn_mks_nama_kategori
                                            )
                                          }
                                        >
                                          Mulai Kerjakan
                                        </Button>
                                      )}
                                      {item.mjs_link_zoom && (
                                        <Button
                                          className="btn btn-soft-primary"
                                          onClick={() =>
                                            handleOpenModal(
                                              item.id_jadwal_asesi,
                                              item.mjs_id_simulasi,
                                              item.mjs_kode_link_zoom,
                                              item.mjs_link_zoom
                                            )
                                          }
                                        >
                                          <i className="fa fa-camera "></i> Link
                                          Zoom
                                        </Button>
                                      )}
                                    </div>
                                  ) : item.status === "proses" ? (
                                    <div>
                                      <Button
                                        className="btn btn-soft-primary m-2"
                                        onClick={() =>
                                          handleChangePage(
                                            item.id_jadwal_asesi,
                                            item.mjs_id_simulasi,
                                            item.msn_mks_nama_kategori
                                          )
                                        }
                                      >
                                        Lanjutkan
                                      </Button>
                                      {item.mjs_link_zoom && (
                                        <Button
                                          className="btn btn-soft-primary"
                                          onClick={() =>
                                            handleOpenModal(
                                              item.mjs_kode_link_zoom,
                                              item.mjs_link_zoom
                                            )
                                          }
                                        >
                                          <i className="fa fa-camera "></i> Link
                                          Zoom
                                        </Button>
                                      )}
                                    </div>
                                  ) : (
                                    <Fragment></Fragment>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      ) : currentDate <= item.mjs_tanggal_pelaksanaan_mulai ? (
                        <Card>
                          <CardBody>
                            <CardTitle>
                              <div className="bg-primary p-3 rounded">
                                <h5 className="font-size-14 text-light mb-0">
                                  {item.mjs_simulasi}
                                </h5>
                              </div>
                            </CardTitle>
                            <div className="text-center">
                              <h3>Coming Soon</h3>
                            </div>
                          </CardBody>
                        </Card>
                      ) : currentDate >=
                        item.mjs_tanggal_pelaksanaan_selesai ? (
                        <Card>
                          <CardBody>
                            <div className="text-center">
                              <h3>Jadwal Terlewat</h3>
                            </div>
                          </CardBody>
                        </Card>
                      ) : (
                        ""
                      )}
                    </Col>
                  ))
                ) : (
                  <div>Loading...</div>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={showModal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setShowModal(!showModal)
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setShowModal(!showModal)
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">{kodelink}</h4>
                <p className="text-muted font-size-14 mb-4">
                  Masuk zoom dengan kode diatas
                </p>

                <div className="input-group rounded bg-light">
                  <Input
                    readOnly
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                    value={linkZoom}
                  />
                  <a
                    href={linkZoom}
                    className="btn btn-primary"
                    color="primary"
                    type="button"
                    id="button-addon2"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleLinkClick}
                  >
                    <i className="bx bxs-paper-plane"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(TasksList)
