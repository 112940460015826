import React, { useState, useEffect } from "react";

const CountdownTimerAdmin = ({ startTime, status }) => {
  const [timeLeft, setTimeLeft] = useState(startTime); // in seconds
  const [isRunning, setIsRunning] = useState(status);

  useEffect(() => {
    let interval;

    if (isRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Cleanup the interval when the component unmounts
  }, [isRunning, timeLeft]);

  useEffect(() => {
    setIsRunning(status);
    setTimeLeft(startTime * 60);
  }, [startTime, status]);

  const formatTime = time => {
    return time < 10 ? "0" + time : time;
  };

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  return (
    <>
    <div className="fw-bold fs-5">
      <i className="bx bxs-timer me-2" />
      {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}

    </div>
    </>
  );
};

export default CountdownTimerAdmin;
