import React, { useEffect, useMemo, useState } from "react"
import { db } from "components/Firebase/Firebase"
import {
    collection,
    query,
    where,
    getDocs,
    updateDoc,
    doc,
    addDoc,
} from "firebase/firestore"
import { Link, useNavigate, useParams } from "react-router-dom"
import { io } from "socket.io-client";

import Swal from "sweetalert2"

const StatusPeserta = props => {
    // const [dataFirebase, setDataFirebase] = useState([]);
    const [statusTimer, setStatusTimer] = useState(null);
    const [getStatus, setGetStatus] = useState(0);
    const socket = io.connect(process.env.REACT_APP_BACKEND_SOCKET_URL, {
        transports: ["websocket"],
        path: '/socket-ac/socket.io',
        extraHeaders: {
            "Access-Control-Allow-Origin": process.env.REACT_APP_URL
        },
    });

    useEffect(() => {
        var myHeaders = new Headers()
        myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("access_token_2")
        )

        var requestOptionsGet = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        }
        const baseURLGet =
            process.env.REACT_APP_BACKEND_URL_VERSION +
            `jawaban/waktu?id_peserta=` +
            props.id_peserta +
            `&id_simulasi=` +
            props.id_simulasi
        fetch(baseURLGet, requestOptionsGet)
            .then(response => response.json())
            .then(result => {
                if (result.meta.code == 200) {
                    setStatusTimer(result.data[0].status_timer)
                }
            })
            .catch(error => {
                console.log("error", error)
            })
    }, [getStatus])

    useEffect(() => {
        socket.emit("join_room", parseInt(props.id_peserta));
    }, [])

    const sendMessageStart = () => {
        Swal.fire({
            title: "PERINGATAN",
            text: "Apakah anda inggin menghentikan waktu pengerjaan ?",
            icon: "warning", // Change the icon to "warning"
            showCancelButton: true, // Show the cancel button
            confirmButtonText: "OK", // Change the button text to "Reload"
            cancelButtonText: "Cancel", // Text for the cancel button
        }).then((result) => {
            if (result.isConfirmed) {
                const room = parseInt(props.id_peserta);
                const message = props.id_simulasi;
                const status = false;
                const accessToken = localStorage.getItem("access_token_2");
                socket.emit("send_message", { message, room, status, accessToken });
                setStatusTimer(false);
            }
        });

    };

    const sendMessageStop = () => {
        Swal.fire({
            title: "PERINGATAN",
            text: "Apakah anda inggin melanjutkan waktu pengerjaan ?",
            icon: "warning", // Change the icon to "warning"
            showCancelButton: true, // Show the cancel button
            confirmButtonText: "Ok", // Change the button text to "Reload"
            cancelButtonText: "Cancel", // Text for the cancel button
        }).then((result) => {
            if (result.isConfirmed) {
                const room = parseInt(props.id_peserta);
                const message = props.id_simulasi;
                const status = true;
                const accessToken = localStorage.getItem("access_token_2");
                socket.emit("send_message", { message, room, status, accessToken });
                setStatusTimer(true);
            }
        });
    };

    useEffect(() => {
        socket.on("receive_message", (data) => {
            if (data.message == props.id_simulasi && data.room == parseInt(props.id_peserta)) {
                setStatusTimer(data.status);
            }
        })
        socket.on("receive_broadcast", (data) => {
            if (data.message == props.id_simulasi) {
                setStatusTimer(data.status)
            }
        })
        socket.on("user_disconnected", (data) => {
            console.log(props.id_peserta)
            if (data == parseInt(props.id_peserta)) {
                // reGet();
                // window.location.reload()
                setGetStatus(getStatus + 1);
            }
        })
    }, [socket])



    return (
        <div>
            {
                statusTimer == true ? (
                    <Link onClick={() => sendMessageStart()} className="btn btn-sm btn-soft-success">
                        <span className=" mdi mdi-timer"></span>
                    </Link>
                ) : statusTimer == false ? (
                    <Link onClick={() => sendMessageStop()} className="btn btn-sm btn-soft-danger">
                        <span className=" mdi mdi-timer"></span>
                    </Link>
                ) : null
            }
        </div>
    )
}
export default StatusPeserta
