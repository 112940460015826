import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DataJadwal from "./DataJadwal"

//Import Images
import profile1 from "assets/images/profile-img.png"

// import charts
import { getUserProfile } from "store/actions"
import { date } from "yup"

const ContactsProfile = props => {
  const navigate = useNavigate()
  const [image, setImage] = useState("")
  const [loading, setLoading] = useState(false)
  const route = localStorage.getItem("access_session")
  const url = process.env.REACT_APP_BACKEND_URL
  const userData = JSON.parse(localStorage.getItem("authUser"))
  document.title = "Profile"
  
  const imagePath = require('assets/images/users/7309681.jpg');

  if (route) {
    navigate(route)
  }
  
  // useEffect(() => {
  //   const requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   }
  //   const baseURL =
  //     process.env.REACT_APP_BACKEND_URL_VERSION +
  //     `auth/session?id_tokenable=` +
  //     userData.id_peserta
  //   fetch(baseURL, requestOptions)
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.meta.code === 200) {
  //         const targetDate = new Date(data.data[0].expires_at) // Your target date
  //         const currentDate = new Date() // Get the current date
  //         const timeDifferenceInMilliseconds = targetDate - currentDate
  //         const timeDifferenceInMinutes =
  //           timeDifferenceInMilliseconds / (1000 * 60)
  //           useAutoLogout(timeDifferenceInMinutes)
  //       } else {
  //         localStorage.removeItem("authUser")
  //         navigate(`/login`)
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       console.log("test")
  //     })
  // }, [])


  const photo_profile = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptionsGet = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "profile?id_peserta=" +
      userData.id_peserta
    fetch(baseURLGet, requestOptionsGet)
      .then(response => response.json())
      .then(result => {
        const image = result.data[0].photo_profile
        const photos = url + image
        if (image) {
          setImage(photos)
        }
      })
  }

  const handleImageChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()

    const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "update-profile"
    var formdata = new FormData()

    setLoading(true)
    formdata.append("id_peserta", userData.id_peserta)
    formdata.append("image", file)

    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }

    fetch(baseURL, requestOptions)
      .then(response => response.json())
      .then(result => {
        photo_profile()
        setLoading(false)
      })
      .catch(error => console.log("error", error))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Profile" />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Selamat Datang</h5>
                        <p>{userData.nama_peserta}</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profile1} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 mt-2">
                  <Row>
                    <Col sm="4">
                      <div className="avatar-md profile-user-wid mb-4">
                        {loading == true ? (
                          <label htmlFor="image-upload">
                            <div
                              role="status"
                              className="ms-2 spinner-border text-secondary"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </label>
                        ) : (
                          <label htmlFor="image-upload">
                            {image ? (
                              <img
                                src={image}
                                alt=""
                                className="img-thumbnail rounded-circle"
                              />
                            ) : (
                              <img
                                src={imagePath}
                                alt=""
                                className="img-thumbnail rounded-circle"
                              />
                            )}
                            <input
                              id="image-upload"
                              type="file"
                              onChange={handleImageChange}
                              style={{ display: "none" }}
                            />
                          </label>
                        )}
                      </div>
                      <h5 className="font-size-15 text-truncate mt-4">
                        {userData.nama_peserta}
                      </h5>
                      <p className="text-muted mb-0 text-truncate">
                        {userData.jabatan}
                      </p>
                    </Col>

                    <Col xl={8}>
                      <div className="pt-4">
                        <Row>
                          <Col xs="6">
                            <p className="text-muted mb-0">Nip :</p>
                            <h5 className="font-size-15">{userData.nip}</h5>
                          </Col>
                          <Col xl="6">
                            <p className="text-muted mb-0">Jenis Kelamin :</p>
                            <h5 className="font-size-15">
                              {userData.jk_peserta}
                            </h5>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lx="8">
              <DataJadwal />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default connect(
)(withRouter(ContactsProfile))
