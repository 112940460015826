import React, { useState, useEffect } from "react"
import CountdownTimer from "./CountdownTimer"

import { Row, Button, Col } from "reactstrap"
import { Status } from "pages/Jadwal/status"
import Swal from "sweetalert2"
import { io } from "socket.io-client"
import { use } from "i18next"

function App(props) {
  const [durasi, setdurasi] = useState()
  const [status, setstatus] = useState(true)
  const [loading, setLoading] = useState(true)
  const userData = JSON.parse(localStorage.getItem("authUser"))
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [showModal, setShowModal] = useState(false)

  const socket = io.connect(process.env.REACT_APP_BACKEND_SOCKET_URL, {
    transports: ["websocket"],
    path: '/socket-ac/socket.io',
    extraHeaders: {
      "Access-Control-Allow-Origin": process.env.REACT_APP_URL,
    },
    query: {
      idSimulasi: props.data.mfm_id_simulasi,
      userId: userData.id_peserta, // Replace with the desired user ID
      accessToken: localStorage.getItem("access_token_2"),
    },
  })

  socket.on('connect', () => {
    console.log('Socket connected:', socket.id);
  });
  socket.on('connect_error', (error) => {
    console.error('Connection failed:', error.message);
  });

  console.log("check socked")

  useEffect(() => {
    socket.emit("join_room", parseInt(userData.id_peserta))
  }, [])

  useEffect(() => {
    socket.on("receive_message", data => {

      if (data.message == props.data.mfm_id_simulasi) {
        if (data.status == false) {
          setstatus(false)
          confrim()
          // Update_status(data.room, data.message, false)
        } else if (data.status == true) {
          Swal.close()
          setstatus(true)
          // Update_status(data.room, data.message, true)
        }
      }
    })

    socket.on("receive_broadcast", data => {
      console.log(data)
      if (data.message == props.data.mfm_id_simulasi) {
        if (data.status == false) {
          console.log('stop')
          setstatus(false)
          confrim()
          Update_status(userData.id_peserta, props.data.mfm_id_simulasi, false)
        } else if (data.status == true) {
          console.log('start')
          Swal.close()
          setstatus(true)
          Update_status(userData.id_peserta, props.data.mfm_id_simulasi, true)
        }

      }
    })

  }, [socket])

  const dateString = props.lastUpdate
  const dateObj = new Date(dateString)
  const formattedDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${dateObj.getDate().toString().padStart(2, "0")}`
  const formattedTime = `${dateObj
    .getHours()
    .toString()
    .padStart(2, "0")}:${dateObj.getMinutes().toString().padStart(2, "0")}`

  const formattedDateTime = `${formattedDate} ${formattedTime}`

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )
    var requestOptionsGet = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/waktu?id_peserta=` +
      userData.id_peserta +
      `&id_simulasi=` +
      props.data.mfm_id_simulasi
    fetch(baseURLGet, requestOptionsGet)
      .then(response => response.json())
      .then(result => {
        if (result.meta.code == 500) {
          const sekarang = new Date()
          const waktuHasil = new Date(
            sekarang.getTime() + props.data.mjs_durasi * 60000
          )

          const jam = waktuHasil.getHours().toString().padStart(2, "0")
          const menit = waktuHasil.getMinutes().toString().padStart(2, "0")
          const detik = waktuHasil.getSeconds().toString().padStart(2, "0")

          const hasil = `${jam}:${menit}:${detik}`

          const baseURL =
            process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/waktu"
          var formdata = new FormData()
          formdata.append("id_peserta", userData.id_peserta)
          formdata.append("id_simulasi", props.data.mfm_id_simulasi)
          formdata.append("batas_waktu", hasil)
          formdata.append("sisa_waktu", props.data.mjs_durasi)

          var myHeaders = new Headers()
          myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("access_token_2")
          )

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
          }

          fetch(baseURL, requestOptions)
            .then(response => response.json())
            .then(result => {
              setdurasi(props.data.mjs_durasi)
            })
            .catch(error => console.log("error", error))
        } else {
          const statustimer = result.data[0].status_timer
          if (localStorage.getItem("connected")) {
            const room = parseInt(userData.id_peserta)
            const message = props.data.mfm_id_simulasi
            const status = true
            socket.emit("send_message", { message, room, status })
            const baseURLGet =
              process.env.REACT_APP_BACKEND_URL_VERSION +
              `jawaban/waktu?id_peserta=` +
              userData.id_peserta +
              `&id_simulasi=` +
              props.data.mfm_id_simulasi +
              `&status=` +
              true
            fetch(baseURLGet, {
              method: "PUT",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token_2"),
                "Content-Type": "application/json",
              },
            })
              .then(response => response.json())
              .then(responseData => {
                console.log("sukses")
              })
              .catch(error => {
                console.log("error", error)
              })
            setstatus(true)
            localStorage.removeItem("connected")
            Swal.close()
          } else {
            setstatus(statustimer)
            if (statustimer === false) {
              confrim()
            } else if (statustimer === true) {
              Swal.close()
            }
          }
          const waktu_logout = result.data[0].waktu_logout // Waktu logout
          const batas_waktu = result.data[0].batas_waktu
          const sisa_waktu = result.data[0].sisa_waktu
          if (sisa_waktu) {
            setdurasi(sisa_waktu)
            updateBatasWaktu(sisa_waktu)
          } else {
            setdurasi(0)
            console.log("waktu habis")
          }
        }
      })
      .catch(error => {
        const sekarang = new Date()
        const waktuHasil = new Date(
          sekarang.getTime() + props.data.mjs_durasi * 60000
        )

        const jam = waktuHasil.getHours().toString().padStart(2, "0")
        const menit = waktuHasil.getMinutes().toString().padStart(2, "0")
        const detik = waktuHasil.getSeconds().toString().padStart(2, "0")

        const hasil = `${jam}:${menit}:${detik}`

        const baseURL =
          process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/waktu"
        var formdata = new FormData()
        formdata.append("id_peserta", userData.id_peserta)
        formdata.append("id_simulasi", props.data.mfm_id_simulasi)
        formdata.append("batas_waktu", hasil)
        formdata.append("sisa_waktu", props.data.mjs_durasi)

        var myHeaders = new Headers()
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("access_token_2")
        )

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        }

        fetch(baseURL, requestOptions)
          .then(response => response.json())
          .then(result => {
            setdurasi(props.data.mjs_durasi)
          })
          .catch(error => console.log("error", error))
      })
  }, [])

  const handleOffline = () => {
    setIsOnline(false)
    setShowModal(true)

    Swal.fire({
      icon: "warning",
      title: "Connection Lost",
      text: "Please check your internet connection and try again.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    })
  }

  const handleOnline = () => {
    setIsOnline(true)
    setShowModal(false)
    // Handle reconnection events (optional)
    Swal.close()
    Swal.fire({
      title: "CONNECTED",
      text: "We recommend reloading for updates.",
      icon: "success", // Change the icon to "success"
      confirmButtonText: "Reload", // Change the button text to "Reload"
    }).then(result => {
      if (result.isConfirmed) {
        socket.on("reconnect", () => {
          console.log("Reconnected to the server.")
          // You can perform any additional actions specific to reconnections here
        })
        localStorage.setItem("connected", "Connection Success !!!")
        window.location.reload()
      }
    })
  }

  useEffect(() => {
    // Add event listeners for online and offline events
    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    // Cleanup function to remove event listeners and close the modal
    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
      setShowModal(false)
      Swal.close()
    }
  }, [])

  const updateBatasWaktu = waktu => {
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/waktu?id_peserta=` +
      userData.id_peserta +
      `&id_simulasi=` +
      props.data.mfm_id_simulasi +
      `&batas_waktu=` +
      waktu
    fetch(baseURLGet, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        console.log("Update batas Waktu")
      })
      .catch(error => {
        console.log("error", error)
      })
  }
  const Update_status = (peserta, simulasi, status) => {
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/waktu?id_peserta=` +
      peserta +
      `&id_simulasi=` +
      simulasi +
      `&status=` +
      status
    fetch(baseURLGet, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        console.log("sukses")
      })
      .catch(error => {
        console.log("error", error)
        Swal.close() // Close loading state
        Swal.fire("Error", "External Error", "error")
      })
  }

  const confrim = () => {
    Swal.fire({
      title: "WAKTU BERHENTI",
      text: "Konfirmasi kepada Admin",
      icon: "error",
      allowOutsideClick: false,
      showConfirmButton: false,
      width: 600,
      padding: 100,
      background: "#fffff",
    })
  }

  return (
    <>
      <Row>
        <Col xl={4}></Col>
        <Col xl={4} className="text-center">
          {durasi >= 0 ? (
            <CountdownTimer
              startTime={durasi}
              status={status}
              jawaban={props.editorData}
              type={props.data.msn_mks_nama_kategori}
              id_simulasi={props.data.mfm_id_simulasi}
              id_peserta={parseInt(userData.id_peserta)}
              id_jadwal_asesi={props.data.id_jadwal_asesi}
              id_jadwal={props.idJadwal}
              datadrag={props.datadrag}
              isOnline={isOnline}
            />
          ) : (
            <p>
              <i className="fa fa-spinner spinner"></i>
              loading..
            </p>
          )}
        </Col>
        <Col xl={4}>
          <p className="text-end font-size-14">
            {props.lastUpdate && <>Last Update : {formattedDateTime}</>}
          </p>
        </Col>
      </Row>
    </>
  )
}

export default App
