import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

function JobList() {
  //meta title
  document.title = "Jadawal"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [jobsList, setJobsList] = useState([])
  const [job, setJob] = useState(null)

  


  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }


  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: "no",
        disableFilters: true,
      },
      {
        Header: "Kode Test",
        accessor: "kode_test",
        disableFilters: true,
      },
      {
        Header: "Tanggal Mulai",
        accessor: "tgl_mulai",
        disableFilters: true,
      },
      {
        Header: "Tanggal Selesai",
        accessor: "tgl_selesai",
        disableFilters: true,
      },
      {
        Header: "Target Jabatan",
        accessor: "target_jabatan",
        disableFilters: true,
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              {/* <li>
                <Link
                  to=""
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    const id = cellProps.row.id
                    handleJobClick(id)
                  }}
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
              </li> */}
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <Link to="/ListSoal" className="btn btn-sm btn-soft-success">
                  <i className=" mdi mdi-clipboard-pulse-outline" id="detailtooltip"></i>
                </Link>
              </li>
              <UncontrolledTooltip placement="top" target="detailtooltip">
                Detail Jadwal
              </UncontrolledTooltip>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = [
    {
      no: 1,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 2,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 3,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 4,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 5,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 6,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 7,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 13,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 12,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 11,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 10,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 9,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 8,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 7,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 6,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 5,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 4,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 3,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 2,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
    {
      no: 1,
      id: 1,
      kode_test: "TEST3456",
      tgl_mulai: "12-09-2003",
      tgl_selesai: "12-09-2003",
      target_jabatan: "Ketua",
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Jadwal" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Jadwal</h5>
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    handleJobClicks={handleJobClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Detail Jadwal CAT - 28 Mar 2023 s/d 05 Apr 2023
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        <tr>
                          <th>Nomer Registrasi</th>
                          <th>:</th>
                          <td>TEST3456</td>
                        </tr>
                        <tr>
                          <th>Password</th>
                          <th>:</th>
                          <td>12345</td>
                        </tr>
                        <tr>
                          <th>Tanggal Mulai</th>
                          <th>:</th>
                          <td>12-09-2003</td>
                        </tr>
                        <tr>
                          <th>Tanggal Selesai</th>
                          <th>:</th>
                          <td>12-09-2003</td>
                        </tr>
                        <tr>
                          <th>Target Jabatan</th>
                          <th>:</th>
                          <td>Ketua</td>
                        </tr>
                        <tr>
                          <th>Instansi</th>
                          <th>:</th>
                          <td>Smartsoft</td>
                        </tr>
                        <tr>
                          <th>Lokasi</th>
                          <th>:</th>
                          <td>Malang</td>
                        </tr>
                        <tr>
                          <th>PIC</th>
                          <th>:</th>
                          <td>Achmad</td>
                        </tr>
                        <tr>
                          <th>Tempat Skala</th>
                          <th>:</th>
                          <td>Bebas</td>
                        </tr>
                        <tr>
                          <th>Tipe Jadwal</th>
                          <th>:</th>
                          <td>CAT</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default JobList
