import React, { useEffect, useState } from "react"
import { db } from "components/Firebase/Firebase"
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  Timestamp,
} from "firebase/firestore"

const InProse = props => {
  const now = new Date()

  // Membuat currentDate dengan format YYYY-MM-DD (mengganti toLocaleDateString)
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, "0") // Bulan dimulai dari 0
  const day = String(now.getDate()).padStart(2, "0")
  const currentDate = `${year}-${month}-${day}`

  // Membuat currentTime dengan format HH:MM:SS (mengganti toLocaleTimeString)
  const hours = String(now.getHours()).padStart(2, "0")
  const minutes = String(now.getMinutes()).padStart(2, "0")
  const seconds = String(now.getSeconds()).padStart(2, "0")
  const currentTime = `${hours}:${minutes}:${seconds}`
  const userData = JSON.parse(localStorage.getItem("authUser"))
  const [dataFirebase, setDataFirebase] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionRef = collection(db, "session_timer")
        const q = query(
          sessionRef,
          where("id_peserta", "==", userData.id_peserta),
          where("id_simulasi", "==", props.id_simulasi)
        )
        const querySnapshot = await getDocs(q)
        const sessionList = querySnapshot.docs.map(doc => doc.data())
        setDataFirebase(sessionList)
      } catch (error) {
        console.log("Error getting documents:", error)
      }
    }

    fetchData()
  }, [])

  // useEffect(() => {
  //   if (dataFirebase[0]) {
  //     const timestamp = dataFirebase[0].created_at;
  //     const dateString = new Timestamp(
  //       timestamp.seconds,
  //       timestamp.nanoseconds
  //     ).toDate();
  //     const date = new Date(dateString);
  //     const formattedDate = date.toISOString().split("T")[0];
  //     if (currentTime > dataFirebase[0].batas_waktu || currentDate > formattedDate) {
  //       handledelete()
  //     }
  //   }
  // }, [])

  // Menentukan kondisi yang dipantau

  function handleUpdateStatus() {
    const params = new URLSearchParams()
    params.append("status_simulasi", "selesai")
    params.append("id_jadwal_asesi", props.id_asesi)
    params.append("id_simulasi", props.id_simulasi)

    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    }

    const url = `${
      process.env.REACT_APP_BACKEND_URL_VERSION
    }Status-simulasi?${params.toString()}`

    fetch(url, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log(error))
  }

  function handledelete() {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    }
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/waktu?id_peserta=` +
      userData.id_peserta +
      `&id_simulasi=` +
      props.id_simulasi
    fetch(baseURL, requestOptions)
      .then(response => response.text())
      .then(result => {
        handleUpdateStatus()
      })
      .catch(error => console.log("error", error))
  }
  return null // Karena komponen ini tidak merender apa pun
}

export default InProse
