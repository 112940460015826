import React, { useState, useEffect, useMemo } from "react"
import PizZip from "pizzip"
import create from "docxtemplater"
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Table,
  Collapse,
  Button,
} from "reactstrap"
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import classnames from "classnames"

const InterviewMonitoring = props => {
  const [loading, setLoading] = useState()
  const [jawabanUrut, setJawabanUrut] = useState()
  const [multiSoal, setMultiSoal] = useState([])
  const [tipe, setTipe] = useState()
  const [fileJawabanPpt, setFileJawabanPpt] = useState()
  const [fileJawabanPdf, setFileJawabanPdf] = useState()
  const url = process.env.REACT_APP_BACKEND_URL
  const [text, setText] = useState("")
  const [accordionOpen, setAccordionOpen] = useState([])

  const toggleAccordion = index => {
    const updatedAccordionOpen = [...accordionOpen]
    updatedAccordionOpen[index] = !updatedAccordionOpen[index]
    setAccordionOpen(updatedAccordionOpen)
  }

  const handleGetJawaban = () => {
    setLoading(true)
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/jawaban-peserta?id_peserta=` +
      props.idPeserta +
      `&id_simulasi=` +
      props.idSimulasi +
      `&id_jadwal=` +
      props.idJadwal
    fetch(baseURLGet, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        // console.log(responseData)
        if (responseData.meta.code === 200) {
          if (responseData.data[0]) {
            setText(responseData.data[0].data.value)
            setLoading(false)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    handleGetJawaban()
  }, [])

  const handleFileChange = e => {
    setLoading(true)
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "Detail-simulasi/get_asesi?id_simulasi=" +
      props.idSimulasi +
      "&id_peserta=" +
      props.idPeserta +
      "&id_jadwal=" +
      props.idJadwal
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          const id_asesi = responseData.data[0].id_jadwal_asesi
          if (id_asesi) {
            handlePostFile(e, id_asesi)
          }
        } else {
          console.log("data tidak ditemukan")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handlePostFile = (e, idAsesi) => {
    const myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    const formdata = new FormData()
    formdata.append("id_simulasi", props.idSimulasi)
    formdata.append("id_peserta", props.idPeserta)
    formdata.append("id_jadwal", props.idJadwal)
    formdata.append("jawaban", e.target.files[0], e.target.files[0].name)
    formdata.append("type", "Tipe 8")
    formdata.append("id_jadwal_asesi", idAsesi)

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION + `jawaban/jawaban-peserta`
    fetch(baseURL, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          handleGetJawaban()
        }
      })
      .catch(error => console.log("error", error))
  }
  return (
    <Card>
      <CardHeader className="bg-primary rounded">
        <Row>
          <Col xl={6} className="text-start">
            <p className="fw-bold text-light">{props.nama_simulasi}</p>
          </Col>
          <Col xl={6} className="text-end">
            <input
              type="file"
              onChange={e => handleFileChange(e)}
              className="form-control"
            />
            <pre className="text-light">Input jawaban hasil interview</pre>
          </Col>
        </Row>
      </CardHeader>
      {text && (
        <CardBody>
          {loading === true ? (
            <div className=" ms-2 mt-2 fw-bold">
              <div
                role="status"
                className="spinner-border text-dark spinner-border-sm"
              >
                <span className="visually-hidden">Loading...</span>
              </div>{" "}
              Loading...
            </div>
          ) : (
            <div>
              <DocViewer
                documents={[
                  {
                    uri: url + text,
                  },
                ]}
                pluginRenderers={DocViewerRenderers}
                style={{ height: 900 }}
              />
            </div>
          )}
        </CardBody>
      )}
    </Card>
  )
}

export default InterviewMonitoring
