import React from "react";
import { Navigate, Router } from "react-router-dom";

const Authmiddleware = (props) => {
  const currentDateTime = new Date()
  const formattedDateTime = currentDateTime.toISOString()
  if (!localStorage.getItem("authUser") || localStorage.getItem('expired_at_2') < formattedDateTime) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
